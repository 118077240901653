// @flow
import moment from "moment"
import * as HelperFunc from "rosters/overview/helpers/functions"
import * as C from "rosters/WebpackRosters/consts"
import type {
  ScheduleType,
  ScheduleEditableParamsType,
  ScheduleRequestResponseType,
  ScheduleBulkCreateResponseType,
  ScheduleBulkRequestResponseType,
  ScheduleBulkCreateOptionsType,
  RDORubyType,
} from "rosters/overview/types"
import * as Mock from "rosters/overview/helpers/mockedData"

export const editRequest = (
  previous_schedule_state: ScheduleType,
  template_id: ?number = null,
  new_changes: ScheduleEditableParamsType
): Promise<ScheduleRequestResponseType> => {
  const time = moment().format(C.DATE_TIME_FMT)
  const transformed_changes = HelperFunc.transformEditableParamsToRubyType(new_changes)
  const request_promise: Promise<ScheduleRequestResponseType> = Promise.resolve({
    sched: HelperFunc.transformSchedule({
      ...previous_schedule_state,
      ...transformed_changes,
      updated_at: time,
    }),
    success: true,
    error_message: "",
  })
  return request_promise
}

export const createRequest = (
  new_schedule: ScheduleType,
  template_id: ?number = null
): Promise<ScheduleRequestResponseType> => {
  const time = moment().format(C.DATE_TIME_FMT)
  const date = moment(new_schedule.date, C.DATE_FMT)
  const transformed_schedule = HelperFunc.transformScheduleToRubyType(new_schedule)
  const request_promise: Promise<ScheduleRequestResponseType> = Promise.resolve({
    sched: HelperFunc.transformSchedule({
      ...transformed_schedule,
      id: Mock.getNewScheduleId(),
      daily_schedule_id: Mock.getDailyScheduleId(date, template_id),
      updated_at: time,
    }),
    success: true,
    error_message: "",
  })
  return request_promise
}

export const deleteRequest = (
  schedule_to_delete: ScheduleType,
  template_id: ?number = null
): Promise<ScheduleRequestResponseType> => {
  const request_promise: Promise<ScheduleRequestResponseType> = Promise.resolve({
    sched: schedule_to_delete,
    success: true,
    error_message: "",
  })
  return request_promise
}

export const bulkDeleteRequest = (
  schedules_to_delete: Array<ScheduleType>,
  template_id: ?number = null
): Promise<ScheduleBulkRequestResponseType> => {
  const request_promise: Promise<ScheduleBulkRequestResponseType> = Promise.resolve({
    scheds: schedules_to_delete,
    success: true,
    error_message: "",
  })
  return request_promise
}

export const bulkCreateRequest = (
  new_schedules: Array<ScheduleType>,
  transformed_rdos: Array<RDORubyType>,
  template_id?: ?number,
  options: ScheduleBulkCreateOptionsType
): Promise<ScheduleBulkCreateResponseType> => {
  const request_promise: Promise<ScheduleBulkCreateResponseType> = Promise.resolve({
    leave_requests: [],
    rdos: [],
    scheds: new_schedules.map((s) => {
      const date = moment(s.date, C.DATE_FMT)
      return {
        ...s,
        id: Mock.getNewScheduleId(),
        daily_schedule_id: Mock.getDailyScheduleId(date, template_id),
      }
    }),
    success: true,
    error_message: "",
  })
  return request_promise
}

export const bulkSortOrder = (new_schedules: Array<{ id: number, sort_order: ?number }>): Promise<mixed> =>
  Promise.resolve({ schedules: new_schedules })
