/* @flow */

import * as React from "react"
// $FlowFixMe immutable is definitely a module
import { fromJS, List, Map, Range } from "immutable"
import { t } from "helpers/i18n"
import * as Timesheet from "timesheets/models/timesheet"
import Button from "components/Button"
import Loader from "components/Loader"
import * as Req from "helpers/request"
import * as Routes from "helpers/routes"
import Modal, { Footer, FooterLeft, FooterRight, Header } from "components/Modal"

import Body from "./Body"
import Connect from "./Connect"
import EmptyState from "./EmptyState"
import { Types } from "./EmptyState/Description"
import styles from "./styles.module.scss"

type Props = {
  onClose: () => void,
  onSave: ({ shift: typeof Map, shift_allowances: * }) => void,
  shift: ?typeof Map,
  timesheet: ?typeof Map,
  user: ?typeof Map,
}

type State = {
  allowances: typeof List,
  forms: typeof List,
  isOpen: boolean,
  isReady: boolean,
}

export class AllowanceModal extends React.PureComponent<Props, State> {
  static defaultProps: {| shift: null, timesheet: null, user: null |} = {
    shift: null,
    timesheet: null,
    user: null,
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      allowances: new List(),
      forms: new List(),
      isOpen: props.shift != null,
      isReady: false,
    }
  }

  UNSAFE_componentWillReceiveProps({ shift, timesheet }: Props) {
    if (shift !== this.props.shift) {
      this.fetchAllowances(shift, timesheet)
      this.setState({
        allowances: new List(),
        forms: new List(),
        isOpen: shift != null,
        isReady: false,
      })
    }
  }

  // Please remove this if you work on this file
  // eslint-disable-next-line flowtype/no-weak-types
  createForms: (shiftAllowances: any, allowances: any, timesheet: ?any) => any = (
    shiftAllowances: *,
    allowances: *,
    timesheet: *
  ) => (Timesheet.isExported(timesheet) ? shiftAllowances : this.padForms(shiftAllowances, allowances))

  handleClose: () => void = () => {
    this.props.onClose()
  }

  // Please remove this if you work on this file
  // eslint-disable-next-line flowtype/no-weak-types
  padForms: (forms: any, allowances: any) => any = (forms: *, allowances: *) => {
    const manualAllowances = (allowances || new List()).filter((allowance) => !allowance.get("automated"))

    const minForms = manualAllowances.size > MIN_FORMS ? MIN_FORMS : manualAllowances.size

    // should have at least minimum forms
    if (forms.size < minForms) {
      return forms.concat(new Range(0, minForms - forms.size).map(() => new Map()))
    }

    // if theres still allowances available add atleast one empty
    if (forms.size < manualAllowances.size) {
      return forms.push(new Map()) // List#push() returns a new list
    }

    // if all allowances used dont add any forms
    return forms
  }

  addForm: () => void = () => {
    this.setState({ forms: this.state.forms.push(new Map()) })
  }

  // eslint-disable-next-line flowtype/no-weak-types
  clearForm: (index: any) => void = (index: *) => {
    this.updateFormValue(index, 0)
    this.clearHoursAndMinutes(index)
  }

  // eslint-disable-next-line flowtype/no-weak-types
  clearHoursAndMinutes: (index: any) => void = (index: *) => {
    this.setState({
      forms: this.state.forms.update(index, (form) => form.merge({ value: 0, value_minutes: 0 })),
    })
  }

  // eslint-disable-next-line flowtype/no-weak-types
  updateFormAllowance: (index: any, value: any) => void = (index: *, value: *) => {
    if (!this.state.allowances) {
      return
    }

    const allowance = this.state.allowances.find((allowance) => allowance.get("id") === value)

    if (!allowance) {
      return
    }

    this.setState({
      forms: this.state.forms.updateIn([index], (form) =>
        form.merge({
          allowance_id: allowance.get("id"),
          automated: allowance.get("automated"),
          name: allowance.get("name"),
          rate: allowance.get("rate"),
          pay_rate_based_period_of_time: allowance.get("pay_rate_based_period_of_time"),
        })
      ),
    })
  }

  // eslint-disable-next-line flowtype/no-weak-types
  updateFormValue: (index: any, value: number) => void = (index: *, value: *) => {
    this.setState({
      forms: this.state.forms.update(index, (form) => form.merge({ value: value })),
    })
  }

  // eslint-disable-next-line flowtype/no-weak-types
  updateFormValueMinutes: (index: any, value: number) => void = (index: *, value: *) => {
    this.setState({
      forms: this.state.forms.update(index, (form) => form.merge({ value_minutes: value })),
    })
  }

  submitForms: () => void = () => {
    const validForms = this.state.forms.filter((a) => {
      if (a.get("pay_rate_based_period_of_time") != null && a.get("pay_rate_based_period_of_time")) {
        return a.get("allowance_id") != null && a.get("value") != null && a.get("value_minutes") != null
      } else {
        return a.get("allowance_id") != null && a.get("value") != null
      }
    })

    this.props.onSave({ shift: this.props.shift, shift_allowances: validForms.toJS() })
    this.props.onClose()
  }

  // eslint-disable-next-line flowtype/no-weak-types
  fetchAllowances: (shift: ?any, timesheet: ?any) => void = (shift: *, timesheet: *) => {
    if (shift != null) {
      Req.get(Routes.shift_applicable_allowances_path(shift.get("id"))).then((res) => {
        const allowances = fromJS(res.data)

        this.setState((state, props) => {
          if (props.shift !== shift) {
            return state
          }

          const formatted_shift_allowances = shift
            .get("shift_allowances")
            .toJS()
            .map((allowance) => {
              if (!allowance.pay_rate_based_period_of_time) {
                return allowance
              }

              return {
                ...allowance,
                value: Math.floor(allowance.value),
                value_minutes: Math.floor((allowance.value % 1) * 60),
              }
            })

          return {
            forms: this.createForms(fromJS(formatted_shift_allowances), allowances, timesheet),
            isReady: true,
            allowances,
          }
        })
      })
    }
  }

  render(): React.Node {
    return (
      <Modal exitable onExit={this.handleClose} open={this.state.isOpen}>
        <Header
          subtitleText={
            this.props.timesheet && !Timesheet.isExported(this.props.timesheet)
              ? t("js.timesheets.allowance_modal.desc")
              : ""
          }
          titleText={t("js.timesheets.allowance_modal.title")}
        />

        {(() => {
          if (!this.state.isReady) {
            return (
              <div className={styles.loader}>
                <Loader size="l" />
              </div>
            )
          }

          if (this.state.allowances.size > 0 && this.state.forms.size > 0) {
            return (
              <Body
                addForm={this.addForm}
                allowances={this.state.allowances}
                clearForm={this.clearForm}
                forms={this.state.forms}
                timesheet={this.props.timesheet}
                updateFormAllowance={this.updateFormAllowance}
                updateFormValue={this.updateFormValue}
                updateFormValueMinutes={this.updateFormValueMinutes}
                user={this.props.user}
              />
            )
          }

          return (
            <EmptyState
              timesheet={this.props.timesheet}
              type={this.state.forms.size === 0 ? Types.EMPTY : Types.INFO}
            />
          )
        })()}

        {this.state.allowances && this.state.allowances.size > 0 ? (
          <Footer>
            <FooterRight>
              {!Timesheet.isExported(this.props.timesheet) && (
                <Button
                  label={t("js.timesheets.allowance_modal.save_allowances")}
                  onClick={this.submitForms}
                  type="theme"
                />
              )}
            </FooterRight>
            <FooterLeft>
              <Button label={t("js.timesheets.allowance_modal.cancel")} onClick={this.handleClose} type="ghost" />
            </FooterLeft>
          </Footer>
        ) : null}
      </Modal>
    )
  }
}

const MIN_FORMS = 3

// Please remove this if you work on this file
// eslint-disable-next-line flowtype/no-weak-types
export default (Connect(AllowanceModal): any)
