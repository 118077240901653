// @flow

import type { AICResponseType } from "rosters/overview/types"

export const getScheduleAICS = (schedule_ids: Array<number>): Promise<AICResponseType> =>
  Promise.resolve({
    aics: [],
    schedules_still_in_queue: [],
    time_not_worked_schedules_still_in_queue: [],
  })

export const recacheRostersAndUsers = (schedule_ids: Array<number>): Promise<mixed> => Promise.resolve()

export default { getScheduleAICS }
