/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import moment from "moment"
import { compose } from "underscore" // eslint-disable-line underscore-to-lodash/prefer-import-lodash

import * as Routes from "helpers/routes"
import * as Time from "helpers/time"
import Request from "helpers/request"

import * as Shift from "timesheets/models/shift"

import Actions from "../actions"

export const shiftsApproveDate = () => (dispatch, getState) => {
  const state = getState()
  const shiftVarianceApprovalRequired = state.currentOrganisation?.get("shift_variance_approval_required", false)
  const shifts = Shift.filterExportedWithNoCosts(
    state.shifts.filter(
      (s) =>
        Shift.isApprovable(s) &&
        (!shiftVarianceApprovalRequired || !Shift.shiftVariance(s).hasVariance) &&
        Shift.passedValidations(
          s,
          state.timesheets.find((t) => t.get("id") === s.get("timesheet_id"))
        )
    )
  )

  shifts.forEach(
    compose(dispatch, (shift) =>
      Actions.shiftsMergeIn({
        id: shift.get("id"),
        shift: { status: Shift.Status.Approved },
      })
    )
  )

  const shift_ids = shifts.toArray().map((shift) => shift.get("id"))

  if (shift_ids.length > 0) {
    Request.post(Routes.timesheets_approve_date_path(), { shift_ids })
      .then(() =>
        shifts.forEach(
          compose(dispatch, (shift) =>
            Actions.shiftsMergeIn({
              id: shift.get("id"),
              shift: { state: Shift.States.Clean },
            })
          )
        )
      )
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch(Actions.userMerge({ error: "sync" }))
        }
      })
  }
}

/**
 * this modules is used to construct all the asynchronous actions
 * involved in updating shifts with tanda. however to keep to
 * a unit-y fashion and the rule of one function one job. this
 * module's job is to take a shift && series of fields
 * to be updated and to construct the correct parameters to
 * be processed by timesheets/logic/reqProcess.
 */

/**
 * helper to builds fields for shiftsSyncTime that clear the shift
 *
 * @param {Immutable.Map} shift to clear times of
 */
export const shiftsClear =
  ({ shift }) =>
  (dispatch) => {
    if (shift.get("breaks")) {
      shift.get("breaks").forEach((sb) =>
        dispatch(
          Actions.shiftsBreakClear({
            shift,
            shiftBreak: sb,
          })
        )
      )
    }

    dispatch(
      Actions.shiftsSyncTime({
        shift,
        fields: Shift.TimeFields.reduce((acc, key) => ({ ...acc, [key]: "" }), {}),
      })
    )
    return dispatch(Actions.shiftsSyncAllowances({ shift, shift_allowances: [] }))
  }

/*
 * clears a shift break
 */
export const shiftsBreakClear =
  ({ shift, shiftBreak }) =>
  (dispatch) => {
    dispatch(
      Actions.shiftsBreakSyncTime({
        shift,
        shiftBreak,
        fields: {
          start: "",
          finish: "",
          length: null,
        },
      })
    )
  }

/**
 * builds params for tanda. to be used with non time fields. the process is:
 *
 *   - append updates to dependent fields
 *   - update local state
 *   - validate timesheet status if status change
 *   - disaptch request action
 *
 * @param {Immutable.Map}  shift to update
 * @param {Object} fields to update
 */
export const shiftsSync =
  ({ shift, fields }) =>
  (dispatch, getState) => {
    const params = { ...fields }

    // optimistically update
    dispatch(Actions.shiftsMergeIn({ id: shift.get("id"), shift: params }))

    // add start and department to shift creations
    if (Shift.hasMockedId(shift.get("id"))) {
      Object.assign(params, {
        timesheet_id: shift.get("timesheet_id"),
        department_id: fields.department_id || shift.get("department_id"),
        start: shift.get("start").format(Time.Formats.DateTime),
      })
    }

    // process request with tanda
    return dispatch(Actions.requestProcess({ shift, params }))
  }

export const addShiftComment =
  ({ shift, fields }) =>
  (dispatch, getState) => {
    const data = {
      model: "Shift",
      id: shift.get("id"),
      user_id: window.current_user.id,
      text: fields.comment,
    }

    Request.post(Routes.notes_path(), data)
      .then(() =>
        compose(dispatch, (shift) =>
          Actions.shiftsMergeIn({
            id: shift.get("id"),
            shift: { state: Shift.States.Clean },
          })
        )
      )
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch(Actions.userMerge({ error: "sync" }))
        }
      })
  }

/**
 * builds params for tanda. to be used for the shift break length (a non-time field)
 *
 *   - append updates to dependent fields
 *   - update local state
 *   - validate timesheet status if status change
 *   - disaptch request action
 *
 * @param {Immutable.Map}  shiftBreak to update
 * @param {Int} new break length
 */
export const shiftsSyncBreakNonTimeChange =
  ({ shiftBreak, fields }) =>
  (dispatch, getState) => {
    const shift = getState().shifts.get(shiftBreak.get("shift_id"))

    dispatch(
      Actions.shiftsBreakMergeIn({
        shift,
        shiftBreakId: shiftBreak.get("id"),
        changes: fields,
      })
    )

    return dispatch(
      Actions.requestProcess({
        shift,
        shiftBreak,
        params: fields,
      })
    )
  }

/**
 * builds params for tanda. to be used with allowances of a shift.
 *
 * @param {Immutable.Map} shift to update
 * @param {Imutable.Map[]} shift_allowances to update
 */
export const shiftsSyncAllowances =
  ({ shift, shift_allowances }) =>
  (dispatch, getState) => {
    const formatAllowanceValue = (allowance) => {
      const value = allowance.pay_rate_based_period_of_time
        ? (parseFloat(allowance.value) || 0) + (parseFloat(allowance.value_minutes) || 0) / 60
        : parseFloat(allowance.value) || 0

      return value
    }

    // optimistically update
    dispatch(
      Actions.shiftsUpdateAllowances({
        id: shift.get("id"),
        shift_allowances: shift_allowances.map((sa) => ({
          ...sa,
          value: formatAllowanceValue(sa),
        })),
      })
    )

    // sync with server
    return dispatch(
      Actions.requestProcess({
        isCachable: false,
        shift,
        params: shift_allowances.map((a) => ({
          allowance_id: a.allowance_id,
          id: a.id,
          value: formatAllowanceValue(a),
        })),
      })
    )
  }

/**
 * validates whether a value is a time and submits a time sync for a shift
 *
 * TODO: maybe move input validation to live in its own thunk
 *
 * @param {Object} $0 data of the update
 * @param {Object} $0.shift related to the update
 * @param {string} $0.field to update
 * @param {string} $0.value to interpret and update the field with
 */
export const shiftsConductTimeUpdate =
  ({ shift, field, value }) =>
  (dispatch, getState) => {
    if (!value) {
      return dispatch(
        Actions.shiftsSyncTime({
          // remote update
          shift: shift,
          fields: { [field]: "" },
        })
      )
    }

    const time = Shift.momentFromInput(value, shift.get("date"), field, shift.get("start"))

    return dispatch(
      Actions.shiftsSyncTime({
        // remote update
        shift,
        fields: { [field]: time.isValid() ? value : "" },
      })
    )
  }

/**
 * builds params for tanda. to be used with time fields. the process is:
 *
 *   - update local state
 *   - validate timesheet status
 *   - validate timesheet chronology
 *   - dispatch request action
 *
 * @param {Shift}  shift to update
 * @param {Object} fields to update
 */
export const shiftsSyncTime =
  ({ shift, fields }) =>
  (dispatch, getState) => {
    const [api, app] = buildTimeUpdates({
      shift,
      fields,
    })

    dispatch(Actions.shiftsMergeIn({ id: shift.get("id"), shift: app }))

    dispatch(
      Actions.timesheetsValidateChronology({
        date: shift.get("date"),
        userId: shift.get("user_id"),
      })
    )

    // if creating a new shift, need to know the timesheet ID
    if (Shift.hasMockedId(shift.get("id"))) {
      Object.assign(api, { timesheet_id: shift.get("timesheet_id") })

      // if the employee was scheduled we also get the team ID from the schedule
      // and pass that on to the new shift
      if (shift.get("department_id")) {
        Object.assign(api, { department_id: shift.get("department_id") })
      }
    }

    return dispatch(
      Actions.requestProcess({
        // process request with tanda. to make sure
        shift: getState().shifts.get(shift.get("id")), // we dont send a malformed shift we lazy
        params: api, // load shift after validation.
      })
    )
  }

/**
 * validates whether a value is a time and submits a time sync for a shift break
 *
 * @param {Object} $0 data of the update
 * @param {Object} $0.shift break related to the update
 * @param {string} $0.field to update
 * @param {string} $0.value to interpret and update the field with
 */
export const shiftsBreakConductTimeUpdate =
  ({ shiftBreak, field, value }) =>
  (dispatch, getState) => {
    const shift = getState().shifts.get(shiftBreak.get("shift_id"))

    if (!value) {
      return dispatch(
        Actions.shiftsBreakSyncTime({
          shiftBreak,
          shift,
          fields: { [field]: "" },
        })
      )
    }

    const time = Shift.momentFromInput(value, shift.get("date"), `break_${field}`, shift.get("start"))

    return dispatch(
      Actions.shiftsBreakSyncTime({
        shiftBreak,
        shift,
        fields: { [field]: time.isValid() ? value : "" },
      })
    )
  }

/**
 * builds params for API updates for a shift break change
 *
 *   - update local state
 *   - validate timesheet status
 *   - validate timesheet chronology
 *   - disaptch request action
 *
 * @param {Shift}  shift to update
 * @param {Object} fields to update
 */
export const shiftsBreakSyncTime =
  ({ shiftBreak, shift, fields }) =>
  (dispatch, getState) => {
    const [api, app] = buildTimeUpdates({
      shift,
      shiftBreak,
      fields,
    })

    dispatch(
      Actions.shiftsBreakMergeIn({
        shift,
        shiftBreakId: shiftBreak.get("id"),
        changes: app,
      })
    )

    dispatch(
      Actions.timesheetsValidateChronology({
        date: shift.get("date"),
        userId: shift.get("user_id"),
      })
    )

    return dispatch(
      Actions.requestProcess({
        shift: getState().shifts.get(shift.get("id")), // reload shift to get validation errors
        shiftBreak,
        params: api,
      })
    )
  }

/**
 * helper: takes a hash of shift/shiftbreak time field values to update a shift/shiftbreak
 * with and calculates the corresponding app and api sideffects
 * also needed.
 *
 * @param {Immutable.Map} shift related to the update
 * @param {Object} fields to update the shift with
 * @return {[Object, Object]} [api, app] field updates along with their corresponding
 *                                       side effects.
 */
const buildTimeUpdates = ({ shift, shiftBreak, fields }) => {
  const { length, ...momentProps } = fields

  const momentFields = Object.entries(momentProps).reduce(
    (acc, [field, value]) => ({
      ...acc,
      [field]: Shift.momentFromInput(
        value,
        shift.get("date"),
        shiftBreak ? `break_${field}` : field,
        shift.get("start")
      ),
    }),
    {}
  )

  // in shiftsBreakClear we set {length: null}
  // we need to pass this on to the API. we don't need to cast it to a moment.
  const initialObject = [{}, {}]
  if ("length" in fields) {
    initialObject[0].length = length
    initialObject[1].length = length || 0
  }

  return Object.entries(momentFields).reduce(([api, app], [field, time]) => {
    api[field] = time.isValid() // api needs to receive the time
      ? time.format(Time.Formats.DateTime) // as a rails date time format string
      : null

    app[field] = time // locally we need the time as a moment

    if (shiftBreak) {
      app.length = getBreakLength(shiftBreak, app)
    }

    return [api, app]
  }, initialObject)
}

/**
 * helper: gets the best new break duration for a shift time update
 */
const getBreakLength = (shiftBreak, updatedFields) => {
  const updatedBreak = {
    start: shiftBreak.get("start"),
    finish: shiftBreak.get("finish"),
    ...updatedFields,
  }

  if (!updatedBreak.finish || !updatedBreak.finish.isValid() || !updatedBreak.start || !updatedBreak.start.isValid()) {
    return null
  }

  return moment.duration(updatedBreak.finish.diff(updatedBreak.start)).asMinutes()
}
