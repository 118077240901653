// @flow

import _ from "lodash"
import moment from "moment"
import i18n from "helpers/i18n"
import * as C from "rosters/WebpackRosters/consts"
import * as ScheduleRequests from "rosters/overview/api/schedule"
import * as HelperFunc from "rosters/overview/helpers/functions"
import * as Constants from "rosters/overview/helpers/constants"
import type { ScheduleDataType as DayViewScheduleType } from "day_view/types"
import type { PublishedScheduleType } from "../publishedSchedule/types"
import type { ShiftSlot } from "../config"
import * as ScheduleBreak from "../scheduleBreak"
import type { ScheduleBulkCreateOptionsType, RDORubyType } from "../../types"
import type {
  ScheduleType,
  SimpleScheduleType,
  ScheduleBulkCreateResponseType,
  ScheduleBulkRequestResponseType,
  ScheduleEditableParamsType,
  ScheduleRequestResponseType,
} from "./types"

export const SCHEDULE_BATCH_THRESHOLD_FOR_PUBLISHING = 100

type BatchedPublish = {|
  schedules: Array<ScheduleType>,
  user_ids: Array<number>,
|}

export const batchSchedulesForPublishing = (
  schedules: Array<ScheduleType>,
  user_ids: Array<number>
): Array<BatchedPublish> => {
  const schedulesByPerson = _.groupBy(schedules, (sched) => sched.user_id)
  user_ids.map((u_id) => {
    schedulesByPerson[String(u_id)] = schedulesByPerson[String(u_id)] || []
  })
  const batches = _.toPairs(schedulesByPerson).reduce(
    (acc, [user_id, schedules]) => {
      let new_acc = acc
      if (acc[0].schedules.length > SCHEDULE_BATCH_THRESHOLD_FOR_PUBLISHING) {
        new_acc = [{ schedules: [], user_ids: [] }, ...acc]
      }
      new_acc[0] = {
        schedules: [...schedules, ...new_acc[0].schedules],
        user_ids: [Number(user_id), ...new_acc[0].user_ids],
      }
      return new_acc
    },
    [{ schedules: [], user_ids: [] }]
  )
  return batches
}

export const getDraggableId = (is_draggable: boolean, date: string, group_id: ?string, user_id: number): string =>
  String(is_draggable) + "~" + date + "~" + String(group_id) + "~" + String(user_id)

export const extractParamsFromDraggableId = (
  draggable_id: string
): {|
  date: string,
  group_id: ?string,
  is_draggable: boolean,
  user_id: number,
|} => {
  const [is_draggable, date, group_id, , user_id] = draggable_id.split("~")
  return {
    is_draggable: is_draggable === "true",
    date,
    group_id: group_id === "null" ? null : group_id,
    user_id: Number(user_id),
  }
}

export const groupByShiftSlots = (
  schedules: Array<ScheduleType>,
  shift_slots: Array<ShiftSlot>
): { [shift_slot_id: string]: Array<ScheduleType> } =>
  schedules.reduce((acc: { [shift_slot_id: string]: Array<ScheduleType> }, s: ScheduleType) => {
    const simple = transformToSimpleDS(s)
    const valid_times = !(simple.start === 0 && simple.finish === 0)
    const matchingSlots = shift_slots.filter((shift_slot) => {
      if (!valid_times) {
        return false
      }

      const matches_start_after = shift_slot.starting_after == null || simple.start >= shift_slot.starting_after * 60
      const matches_start_before = shift_slot.starting_before == null || simple.start < shift_slot.starting_before * 60
      const matches_end_after = shift_slot.ending_after == null || simple.finish > shift_slot.ending_after * 60
      const matches_end_before = shift_slot.ending_before == null || simple.finish <= shift_slot.ending_before * 60
      return matches_start_after && matches_start_before && matches_end_after && matches_end_before
    })
    matchingSlots.map((slot) => {
      const key = String(slot.id)
      acc[key] = [...(acc[key] || []), s]
    })
    if (matchingSlots.length === 0) {
      const key = String(Constants.DEFAULT_SHIFT_SLOT.id)
      acc[key] = [...(acc[key] || []), s]
    }
    return acc
  }, {})

export const needsPublishing = (s: ScheduleType): boolean =>
  (s.user_id !== Constants.DEFAULT_USER.id || s.last_published_at != null) &&
  (s.last_published_at == null || (s.updated_at != null && s.last_published_at < s.updated_at))

export const needsPublishingInclVacant = (s: ScheduleType): boolean =>
  s.last_published_at == null || (s.updated_at != null && s.last_published_at < s.updated_at)

export const edit = (
  previous_schedule_state: ScheduleType,
  template_id: ?number,
  new_changes: ScheduleEditableParamsType
): Promise<ScheduleRequestResponseType> =>
  ScheduleRequests.editRequest(previous_schedule_state, template_id, new_changes)

export const create = (new_schedule: ScheduleType, template_id: ?number): Promise<ScheduleRequestResponseType> =>
  ScheduleRequests.createRequest(new_schedule, template_id)

export const destroy = (schedule_to_delete: ScheduleType, template_id: ?number): Promise<ScheduleRequestResponseType> =>
  ScheduleRequests.deleteRequest(schedule_to_delete, template_id)

export const bulkDelete = (
  schedules_to_delete: Array<ScheduleType>,
  template_id: ?number
): Promise<ScheduleBulkRequestResponseType> => ScheduleRequests.bulkDeleteRequest(schedules_to_delete, template_id)

export const bulkCreate = (
  new_schedules: Array<ScheduleType>,
  transformed_rdos: Array<RDORubyType>,
  template_id: ?number,
  options: ScheduleBulkCreateOptionsType
): Promise<ScheduleBulkCreateResponseType> =>
  ScheduleRequests.bulkCreateRequest(new_schedules, transformed_rdos, template_id, options)

export const bulkSortOrder = (new_schedules: Array<{ id: number, sort_order: ?number }>): Promise<mixed> =>
  ScheduleRequests.bulkSortOrder(new_schedules)

const OBSCENELY_LARGE_NUMBER = Number.MAX_SAFE_INTEGER / 2

export const sort = (schedules: Array<ScheduleType>): Array<ScheduleType> =>
  _.sortBy(schedules, (s) => s.sort_order || (s.id < 0 ? OBSCENELY_LARGE_NUMBER + s.id * -1 : s.id))

export const extractEditableParams = (schedule: ScheduleType): ScheduleEditableParamsType => ({
  // Leon's comment for here from https://github.com/TandaHQ/payaus/pull/14347 : Must wait till we stop writing to breaks before we remove this.
  breaks: schedule.breaks || "",
  creation_method: schedule.creation_method,
  daily_schedule_id: schedule.daily_schedule_id,
  date: schedule.date,
  department_id: schedule.department_id,
  finish: schedule.finish,
  record_id: schedule.platform_record_id,
  schedule_breaks: schedule.schedule_breaks,
  shift_detail_id: schedule.shift_detail_id,
  sort_order: schedule.sort_order,
  start: schedule.start,
  user_id: schedule.user_id,
})

export const getDiff = (
  beforeSchedule: ScheduleType,
  afterSchedule: ScheduleType
): {
  after: ScheduleEditableParamsType,
  before: ScheduleEditableParamsType,
  changedProps: Array<$Keys<ScheduleEditableParamsType>>,
} => {
  const paramsBefore = extractEditableParams(beforeSchedule)
  const paramsAfter = extractEditableParams(afterSchedule)
  // $FlowFixMe this is fine
  const before: ScheduleEditableParamsType = {}
  // $FlowFixMe this is fine
  const after: ScheduleEditableParamsType = {}
  const changedProps: Array<$Keys<ScheduleEditableParamsType>> = []
  const props = Object.keys(paramsBefore)
  props.map((key: string) => {
    const same = paramsBefore[key] === paramsAfter[key]
    if (!same) {
      before[key] = paramsBefore[key]
      after[key] = paramsAfter[key]
      // $FlowFixMe this is definitely of type '$Key' but we cant refine
      changedProps.push(key)
    }
  })
  return {
    before,
    after,
    changedProps,
  }
}

export const isShiftNotRostered = (s: ScheduleType): boolean =>
  (s.needs_acceptance || s.acceptance_status === "accepted") && !s.start && !s.finish

export const by15Minutes = (
  schedules: Array<ScheduleType>,
  ref_date: string,
  hours: number = 48
): { [minute: string]: number } => {
  const ref_date_unix = Math.floor(HelperFunc.dateTimeToMin(ref_date + " 00:00:00"))
  const time_idx: Array<number> = _.range(0, hours * 60, 15)
  const simple_schedule_ds: Array<SimpleScheduleType> = schedules
    .filter((s) => s.start && s.finish)
    .map((s) => transformToSimpleDS(s, ref_date_unix))
  const obj: { [minute: string]: number } = {}
  time_idx.forEach((minute) => {
    const filtered_schedule_ds = simple_schedule_ds.filter((s) => isMinuteDuringWorkTime(s, minute))
    const unpaid_breaks_with_times_hours = filtered_schedule_ds
      .map((s) => unpaidBreakLengthInPeriod(s, minute) || 0)
      .reduce((acc, val) => acc + val, 0)
    obj[String(minute)] = filtered_schedule_ds.length - unpaid_breaks_with_times_hours
  })
  return obj
}

export const unpaidBreakLengthInPeriod = (sched: SimpleScheduleType, minute: number): number => {
  // get the breaks that overlap with the current 15 minute period
  const breaks = sched.schedule_breaks.filter(
    (b) =>
      !b.paid &&
      b.start &&
      b.finish &&
      ((b.start <= minute && b.finish >= minute) || (b.start <= minute + 15 && b.finish >= minute + 15))
  )

  if (breaks.length === 0) {
    return 0
  }
  // calculate how many minutes of the breaks are within the current 15 minute period
  const totalUnpaidBreakLength = breaks
    .map((b) => {
      const start: number = b.start || 0
      const finish: number = b.finish || 0
      return start && finish ? Math.min(finish, minute + 15) - Math.max(start, minute) : 0
    })
    .reduce((acc, val) => acc + val, 0)

  // convert from number of minutes to a proportion of the 15 minute period.
  // e.g. if the break takes 7.5 minutes of the 15 minute period, return 0.5
  return totalUnpaidBreakLength / 15 || 0
}

export const automaticBreakLengthInPeriod = (sched: ScheduleType): number => {
  const all_breaks = sched.schedule_breaks
  if (all_breaks.length === 0) {
    return 0
  }
  const breaks = all_breaks.filter(
    (b) => !b.paid && ((b.start === null && b.finish === null) || (b.start === "0" && b.finish === "0"))
  )
  // since these have no times, sum the length, then subtract from the total in
  // app/assets/webpack/rosters/overview/views/reporting/DayBreakdown/totals.jsx
  return breaks.map((b) => b.length).reduce((acc, val) => acc + val, 0) / 15 || 0
}

export const isMinuteDuringWorkTime = (sched: SimpleScheduleType, minute: number): boolean =>
  sched.start <= minute && sched.finish > minute

export const isMinuteDuringBreakTime = (sched: SimpleScheduleType, minute: number): boolean =>
  _.some(
    sched.schedule_breaks.map((b) => b.start != null && b.finish != null && b.start <= minute && b.finish > minute)
  )

export const sameHours = (schedule: ScheduleType, rhw_schedule: ScheduleType): boolean => {
  const isSameSchedule =
    schedule.start === rhw_schedule.start &&
    schedule.finish === rhw_schedule.finish &&
    schedule.date === rhw_schedule.date

  const mappedSchedule = _.sortBy(
    schedule.schedule_breaks.map((sb) => ({
      ..._.omit(sb, ["id"]),
      start: HelperFunc.dateTimeToTime(sb.start),
      finish: HelperFunc.dateTimeToTime(sb.finish),
    })),
    ["start", "finish"]
  )

  const mappedRhwSchedule = _.sortBy(
    rhw_schedule.schedule_breaks.map((sb) => ({
      ..._.omit(sb, ["id"]),
      start: HelperFunc.dateTimeToTime(sb.start),
      finish: HelperFunc.dateTimeToTime(sb.finish),
    })),
    ["start", "finish"]
  )

  const isSameBreaks = _.isEqual(mappedSchedule, mappedRhwSchedule)

  const totalScheduleBreakLengths = schedule.schedule_breaks.reduce((acc, sb) => acc + sb.length, 0)

  const isSameAutomaticBreakLength =
    rhw_schedule.automatic_break_length &&
    rhw_schedule.automatic_break_length > 0 &&
    (rhw_schedule.automatic_break_length === schedule.automatic_break_length ||
      rhw_schedule.automatic_break_length === totalScheduleBreakLengths)

  const result = isSameSchedule && (isSameBreaks || isSameAutomaticBreakLength)

  return Boolean(result)
}

export const getHash = (s1: ScheduleType): string => {
  const hash =
    String(s1.start) +
    "~" +
    String(s1.finish) +
    "~" +
    String(s1.date) +
    "~" +
    String(s1.schedule_breaks || s1.automatic_break_length) +
    "~" +
    String(s1.user_id) +
    "~" +
    String(s1.department_id)
  return hash
}

export const isSame = (s1: ScheduleType, s2: ScheduleType): boolean => {
  const result =
    s1.start === s2.start &&
    s1.finish === s2.finish &&
    s1.date === s2.date &&
    (s1.schedule_breaks === s2.schedule_breaks || s1.automatic_break_length !== s2.automatic_break_length) &&
    s1.department_id === s2.department_id
  return result
}

export const someRequirePublishing = (
  schedules: Array<ScheduleType>,
  publishedSchedules: Array<PublishedScheduleType>
): boolean =>
  schedules.length !== publishedSchedules.length ||
  schedules.some((s) => !publishedSchedules.some((ps) => isPublishedSame(s, ps)))

export const isPublishedSame = (sched: ScheduleType, ps: PublishedScheduleType): boolean => {
  const result =
    sched.start === ps.start &&
    sched.finish === ps.finish &&
    sched.date === ps.date &&
    sched.department_id === ps.department_id &&
    sched.shift_detail_id === ps.shift_detail_id &&
    sched.automatic_break_length === ps.automatic_break_length &&
    !ScheduleBreak.someRequirePublishing(sched.schedule_breaks, ps.published_schedule_breaks)
  return result
}

export const normaliseTimes = (
  date: string,
  maybe_start: ?string,
  maybe_finish: ?string
): $Exact<{
  finish: ?string,
  start: ?string,
}> => {
  if (date && maybe_start && maybe_finish) {
    const start: string = maybe_start
    const finish: string = maybe_finish
    const start_parts: Array<string> = start.split(" ")
    const finish_parts: Array<string> = finish.split(" ")
    const finish_is_overnight = finish_parts[1] < start_parts[1]
    return {
      start: start && date + " " + start_parts[1],
      finish:
        finish && finish_is_overnight
          ? moment(date, C.DATE_FMT).add(1, "days").format(C.DATE_FMT) + " " + finish_parts[1]
          : date + " " + finish_parts[1],
    }
  } else {
    return {
      start: !maybe_start ? maybe_start : date + " " + maybe_start.split(" ")[1],
      finish: !maybe_finish ? maybe_finish : date + " " + maybe_finish.split(" ")[1],
    }
  }
}

export const transformToSimpleDS = (sched: ScheduleType, maybe_ref_time_to_subtract: ?number): SimpleScheduleType => {
  const ref_date = sched.date
  const ref_time_to_subtract: number =
    maybe_ref_time_to_subtract || HelperFunc.dateTimeToMinWithOffset(ref_date + " 00:00:00", ref_date)
  const invalid_times = !sched.start || !sched.finish
  const start = invalid_times
    ? 0
    : Math.floor(HelperFunc.dateTimeToMinWithOffset(sched.start || "", ref_date) - ref_time_to_subtract) || 0
  const finish = invalid_times
    ? 0
    : Math.floor(HelperFunc.dateTimeToMinWithOffset(sched.finish || "", ref_date)) - ref_time_to_subtract || 0
  return {
    id: sched.id,
    start,
    automatic_break_length: sched.automatic_break_length ? Number(sched.automatic_break_length) : null,
    finish,
    schedule_breaks: sched.schedule_breaks.map((b) => {
      const breakStart =
        b.start != null
          ? Math.floor(HelperFunc.dateTimeToMinWithOffset(b.start, ref_date)) - ref_time_to_subtract
          : null
      const breakFinish =
        b.finish != null
          ? Math.floor(HelperFunc.dateTimeToMinWithOffset(b.finish, ref_date)) - ref_time_to_subtract
          : null
      // if break is before schedule start we assume its an overnight break e.g. 1:00am-1:10am
      const overnightAdjustedBreakStart =
        start != null && breakStart != null && breakStart < start ? breakStart + 24 * 60 : breakStart
      const overnightAdjustedBreakFinish =
        finish != null && breakFinish != null && breakFinish < start ? breakFinish + 24 * 60 : breakFinish
      // if break finish is before break start we assume the break is over midnight e.g. 11:45pm-12:15am
      const midnightAdjustedBreakFinish =
        overnightAdjustedBreakStart != null && overnightAdjustedBreakFinish != null
          ? overnightAdjustedBreakFinish < overnightAdjustedBreakStart
            ? overnightAdjustedBreakFinish + 24 * 60
            : overnightAdjustedBreakFinish
          : null
      return {
        id: b.id,
        start: overnightAdjustedBreakStart || 0,
        finish: midnightAdjustedBreakFinish || 0,
        length: b.length,
        paid: b.paid,
        paid_meal_break: b.paid_meal_break,
      }
    }),
  }
}

export const sumBreaksForSimpleSchedule = (schedule: SimpleScheduleType): number =>
  schedule.schedule_breaks.filter((sb) => !sb.paid && !isNaN(sb.length)).reduce((acc, sb) => acc + sb.length, 0)

export const sumRosteredHoursForSimpleSchedule = (schedule: SimpleScheduleType): number =>
  schedule.finish - schedule.start - sumBreaksForSimpleSchedule(schedule)

export const sumRosteredHoursForSimpleSchedules = (schedules: Array<SimpleScheduleType>): number =>
  schedules.map(sumRosteredHoursForSimpleSchedule).reduce<number>((acc, s: number) => acc + s, 0)

export const sumRosteredHoursForSchedules = (schedules: Array<ScheduleType>): number =>
  sumRosteredHoursForSimpleSchedules(schedules.map((s) => transformToSimpleDS(s, 0)))

export const sumRosteredHoursForSchedule = (schedule: ScheduleType): number =>
  sumRosteredHoursForSimpleSchedule(transformToSimpleDS(schedule, 0))

export const sumBreakLengthForSchedule = (schedule: ScheduleType): number =>
  sumBreaksForSimpleSchedule(transformToSimpleDS(schedule, 0))

export const sumBreakLengthForPrintableSchedule = (schedule: ScheduleType): number => {
  const simpleSchedule = transformToSimpleDS(schedule, 0)
  return simpleSchedule.schedule_breaks.filter((sb) => !isNaN(sb.length)).reduce((acc, sb) => acc + sb.length, 0)
}

export const transformAllToDayViewType = (scheds: Array<ScheduleType>): Array<DayViewScheduleType> =>
  scheds.map(transformToDayViewType)

export const transformToDayViewType = (sched: ScheduleType): DayViewScheduleType => {
  const ref_time_to_subtract = HelperFunc.dateTimeToMin(moment(sched.date, C.DATE_FMT).format(C.DATE_TIME_FMT))
  const start = sched.start != null && sched.start !== "" ? moment(sched.start, C.DATE_TIME_FMT) : null
  const finish = sched.finish != null && sched.finish !== "" ? moment(sched.finish, C.DATE_TIME_FMT) : null
  return {
    breaks: sched.breaks,
    department_id: String(sched.department_id),
    finish_minute: finish && Math.floor(HelperFunc.dateTimeToMin(sched.start || "") - ref_time_to_subtract),
    finish_time: finish,
    id: String(sched.id),
    schedule_breaks: sched.schedule_breaks,
    start_minute: start && Math.floor(HelperFunc.dateTimeToMin(sched.start || "") - ref_time_to_subtract),
    start_time: start,
    user_id: sched.user_id === Constants.DEFAULT_USER.id ? null : sched.user_id,
  }
}

export const toUsersRosterTimeFormat: (time_str: ?string) => string = _.memoize((time_str: ?string) =>
  time_str != null ? window.time_formatter.rosters_time(moment(time_str, C.DATE_TIME_FMT)) : ""
)

export const convertStartFinishTimeToDateTime = (
  start: ?string,
  finish: ?string,
  ref_date: string
): $Exact<{ finish: ?string, start: ?string }> => {
  const start_time_str: ?string = start
  const finish_time_str: ?string = finish
  const start_datetime = start_time_str ? moment(ref_date + " " + start_time_str, C.DATE_TIME_FMT) : null
  const finish_datetime = finish_time_str ? moment(ref_date + " " + finish_time_str, C.DATE_TIME_FMT) : null
  const adjusted_finish_datetime: ?moment =
    finish_datetime != null && start_datetime != null && finish_datetime.isBefore(start_datetime)
      ? finish_datetime.add(1, "day")
      : finish_datetime
  const new_start_time: ?string = start_datetime ? start_datetime.format(C.DATE_TIME_FMT) : null
  const new_finish_time: ?string = adjusted_finish_datetime ? adjusted_finish_datetime.format(C.DATE_TIME_FMT) : null
  return {
    start: new_start_time,
    finish: new_finish_time,
  }
}

export const removeDuplicates = (
  newSchedules: Array<ScheduleType>,
  existingSchedules: Array<ScheduleType>
): $Exact<{
  existingScheduleDuplicates: Array<ScheduleType>,
  existingSchedulesWithoutDuplicates: Array<ScheduleType>,
  newSchedulesWithoutDuplicates: Array<ScheduleType>,
}> => {
  const newSchedulesHashes = newSchedules.map(getHash)
  const existingSchedulesHashes = existingSchedules.map(getHash)
  const duplicateScheduleHashes = newSchedulesHashes.filter((s) => existingSchedulesHashes.includes(s))
  const newSchedulesWithoutDuplicates = newSchedules.filter((s) => !duplicateScheduleHashes.includes(getHash(s)))
  const [existingSchedulesWithoutDuplicates, existingScheduleDuplicates] = _.partition(
    existingSchedules,
    (s) => !duplicateScheduleHashes.includes(getHash(s))
  )
  return {
    existingScheduleDuplicates,
    existingSchedulesWithoutDuplicates,
    newSchedulesWithoutDuplicates,
  }
}

export const scheduleBreaksAsList = (schedule_breaks: Array<ScheduleBreak.ScheduleBreakType>): string => {
  let break_list = ""
  if (schedule_breaks.length === 0) {
    return break_list
  }

  schedule_breaks.forEach(function (sb, index) {
    if (sb.start != null && sb.finish != null) {
      const break_start = sb.start.split(" ")[1].slice(0, 5)
      const break_end = sb.finish.split(" ")[1].slice(0, 5)
      const break_paid = sb.paid
        ? i18n.t("js.rosters.rosters_overview.paid_break")
        : i18n.t("js.rosters.rosters_overview.unpaid_break")
      const whole_break =
        index === schedule_breaks.length - 1
          ? `${break_start}-${break_end} (${break_paid})`
          : `${break_start}-${break_end} (${break_paid}),`

      break_list += whole_break
    }
  })
  return break_list
}
