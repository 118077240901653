// @flow

export const STAT_TYPES = {
  TOTAL_HOURS_AND_SALES_PER_LABOR_HOUR: "total_hours_and_sales_per_labor_hour",
  TOTAL_SALES_AND_SALES_PER_LABOUR_HOURS: "total_sales_and_wage_percentage_of_revenue",
  TOTAL_SCHEDULE_COSTS_AND_AVG_COST_PER_ROSTERED_HOUR: "total_schedule_costs_and_avg_cost_per_rostered_hour",
  TOTAL_SCHEDULE_COSTS_AND_LABOUR_BUDGET: "total_schedule_costs_and_labour_budget",
  TOTAL_HOURS_AND_LABOUR_BUDGET: "total_hours_and_labour_budget",
  TOTAL_HOURS_AND_RECOMMENDED_HOURS: "total_hours_and_recommended_hours",
  WAGE_PERCENTAGE_OF_REVENUE_VS_BUDGET: "wage_percentage_of_revenue_vs_budget",
  SALES_PER_LABOR_HOUR_VS_BUDGET: "sales_per_labor_hour_vs_budget",
  PREDICTED_WAGE_AS_PCT_REVENUE_VS_BUDGETED_WAGE_AS_PCT_REVENUE:
    "predicted_wage_as_pct_revenue_vs_budgeted_wage_as_pct_revenue",
  PREDICTED_SPLH_VS_BUDGETED_SPLH: "predicted_splh_vs_budgeted_splh",
  PREDICTED_SALES_VS_BUDGETED_SALES: "predicted_sales_vs_budgeted_sales",
  PREDICTED_EMPLOYEE_HOURS_VS_BUDGETED_EMPLOYEE_HOURS: "predicted_employee_hours_vs_budgeted_employee_hours",
  PREDICTED_EMPLOYEE_COSTS_VS_BUDGETED_EMPLOYEE_COSTS: "predicted_employee_costs_vs_budgeted_employee_costs",
  LABOUR_BUDGET: "labour_budget",
  TOTAL_RECOMMENDED_HOURS: "total_recommended_hours",
  TOTAL_SALES: "total_sales",
  SALES_PER_LABOR_HOUR: "sales_per_labor_hour",
  WAGE_PERCENTAGE_OF_REVENUE: "wage_percentage_of_revenue",
  TOTAL_HOURS: "total_hours",
  TOTAL_SCHEDULE_COSTS: "total_schedule_costs",
  AVG_COST_PER_ROSTERED_HOUR: "avg_cost_per_rostered_hour",
  WAGE_PERCENTAGE_OF_REVENUE_WITH_ONCOSTS: "wage_percentage_of_revenue_with_oncosts",
  TOTAL_SALES_AND_WAGE_PERCENTAGE_OF_REVENUE: "total_sales_and_wage_percentage_of_revenue",
  TOTAL_SCHEDULE_COSTS_WITH_ONCOSTS: "total_schedule_costs_with_oncosts",
  TOTAL_SCHEDULE_OVERTIME_COSTS: "total_schedule_overtime_costs",
  TOTAL_SCHEDULES: "total_schedules",
  BASE_RATE: "base_rate",
  TOTAL_HRS_INCL_LEAVE: "total_hrs_incl_leave",
  TOTAL_HRS_INCL_LEAVE_AND_UNPAID: "total_hrs_incl_leave_and_unpaid",
  VACANT_SCHEDULES_COUNT: "vacant_schedules_count",
}

export const METRIC_TYPES = {
  HOURS: "hours",
  CURRENCY: "currency",
  PERCENTAGE: "percentage",
  NONE: "none",
  SPLH: "splh",
  SALES: "sales",
}
