// @flow
import * as CostRequests from "rosters/overview/api/costs"
import type { AICResponseType } from "../../types"
import type { AICRubyType } from "./types"

export const getScheduleAICS = (
  schedule_ids: Array<number>,
  time_not_worked_schedule_ids: Array<number>,
  template_id: ?number
): Promise<AICResponseType> => CostRequests.getScheduleAICS(schedule_ids, time_not_worked_schedule_ids, template_id)

export const recacheRostersAndUsers = (user_ids: Array<number>, roster_ids: Array<number>): Promise<mixed> =>
  CostRequests.recacheRostersAndUsers(roster_ids, user_ids)

export const getAICLength = (aic: AICRubyType): number => {
  const start = aic.times[0] || 0
  const maybeFinish = aic.times[1] || 0
  const finish = maybeFinish < start ? maybeFinish + 24.0 : maybeFinish
  return finish - start
}

export default getScheduleAICS
