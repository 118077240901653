// @flow
import _ from "lodash"
import { DEFAULT_USER } from "rosters/overview/helpers/constants"
import type { SortStaffOptions } from "../../viewOptions"
import type { Schema } from "./types"

export const hasEmploymentEnded = (user: Schema, date: ?string): boolean =>
  user.id != null && user.id !== DEFAULT_USER.id && date != null && user.employment_end_date != null && date
    ? date > user.employment_end_date
    : false

export const sort = (users: Array<Schema>, sortOption: SortStaffOptions): Array<Schema> => {
  switch (sortOption) {
    case "custom":
      return _.sortBy(sort(users, "first_name"), (u) =>
        u.sort_order != null ? u.sort_order : Number.MAX_SAFE_INTEGER / 2
      )
    case "last_name":
      return _.sortBy(users, (u) =>
        u.id !== DEFAULT_USER.id ? _.last((u.name || "").trim().split(" "))?.toLowerCase() : "zzzzzzzzzzz"
      )
    case "first_name":
    default:
      return _.sortBy(users, (u) => (u.id !== DEFAULT_USER.id ? u.name?.toLowerCase() : "zzzzzzzzzzz"))
  }
}

export default sort
