// @flow

import _ from "lodash"
import moment from "moment"
import * as C from "rosters/WebpackRosters/consts"
import { t as globalT } from "helpers/i18n"
import type {
  GlobalState,
  UserType,
  ScheduleType,
  ScheduleValidationType,
  RosterValidationType,
  ValidationSeverityType,
} from "../../types"
import * as Constants from "../constants"
import * as HelperFunctions from "../functions"
import * as ValidationHelpers from "./validationHelpers"

const t = (key, ...args) => globalT(`js.rosters.rosters_overview.validations.${key}`, ...args)

/**
 * Validations where the comparison takes place between a schedule and a configured rule/value
 *
 * https://tandadocs.atlassian.net/wiki/spaces/DEV/pages/2222292993/The+Validators+and+How+They+Work
 */

export const validateEarliestStart = (
  value: ?number,
  schedule: ScheduleType,
  allSchedulesInCollection: Array<ScheduleType>,
  severity: ValidationSeverityType
): Array<ScheduleValidationType> => {
  const errors = []
  if (value == null || !allSchedulesInCollection.length) return errors

  // ensures only the first schedule is validated in the event of contiguous shifts
  const scheduleToValidate = allSchedulesInCollection[0]
  const isError: boolean =
    scheduleToValidate.start != null &&
    HelperFunctions.dateTimeToMinutes(scheduleToValidate.start, scheduleToValidate.date) < value
  const type = "earliest_start"

  if (isError) {
    allSchedulesInCollection.forEach((schedule) => {
      errors.push({
        schedule_id: schedule.id,
        error: {
          type: type,
          name: t(`name.${type}`),
          message: t(`message.${type}`, { earliest_start: HelperFunctions.minutesToTime(value) }),
          severity: severity,
          severity_fallback_sort: -value,
        },
      })
    })
  }

  return errors
}

export const validateLatestFinish = (
  value: ?number,
  earliestStartValue: ?number,
  schedule: ScheduleType,
  allSchedulesInCollection: Array<ScheduleType>,
  severity: ValidationSeverityType
): Array<ScheduleValidationType> => {
  const errors = []
  if (value == null || !allSchedulesInCollection.length) return errors

  // ensures only the last schedule is validated in the event of contiguous shifts
  const scheduleToValidate = allSchedulesInCollection[allSchedulesInCollection.length - 1]

  let normalisedTime = value
  if (earliestStartValue && earliestStartValue > value) {
    // overnight
    normalisedTime = value + 24 * 60
  }

  const isError: boolean =
    scheduleToValidate.finish != null &&
    HelperFunctions.dateTimeToMinutes(scheduleToValidate.finish, scheduleToValidate.date) > normalisedTime
  const type = "latest_finish"

  if (isError) {
    allSchedulesInCollection.forEach((schedule) => {
      errors.push({
        schedule_id: schedule.id,
        error: {
          type: type,
          name: t(`name.${type}`),
          message: t(`message.${type}`, { latest_start: HelperFunctions.minutesToTime(value) }),
          severity: severity,
          severity_fallback_sort: value,
        },
      })
    })
  }

  return errors
}

export const validateMinGap = (
  value: ?string,
  userId: string,
  schedules: Array<ScheduleType>,
  severity: ValidationSeverityType,
  state: GlobalState
): Array<RosterValidationType> => {
  const errors = []
  if (value == null || Number(userId) === Constants.DEFAULT_USER.id) return errors

  const scheduleFinishes: Array<[string, number, moment]> = schedules.map((schedule) => [
    schedule.date,
    schedule.id,
    moment(schedule.finish, C.DATE_TIME_FMT),
  ])

  const scheduleStarts: Array<[string, number, moment]> = schedules.map((schedule) => [
    schedule.date,
    schedule.id,
    moment(schedule.start, C.DATE_TIME_FMT),
  ])

  // type of each gap: [next_schedule_id, previous_schedule_id, gap between them]
  const gaps: Array<[number, number, number]> = _.flatten(
    scheduleFinishes.map(([scheduleFinishDate, scheduleFinishId, scheduleFinishTime]) =>
      scheduleStarts
        .filter(
          ([scheduleStartDate, scheduleStartId, scheduleStartTime]) =>
            scheduleFinishId !== scheduleStartId && scheduleFinishDate !== scheduleStartDate
        )
        .map(([scheduleStartDate, scheduleStartId, scheduleStartTime]) => [
          scheduleStartId,
          scheduleFinishId,
          scheduleStartTime.diff(scheduleFinishTime, "hours", true),
        ])
        .filter(Boolean)
    )
  )

  const gapsUnderMin = gaps.filter(
    ([startScheduleId, finishScheduleId, gap]) =>
      startScheduleId !== finishScheduleId &&
      // gaps less than this threshold are gaps within contiguous shifts,
      // they can't be considered to be a gap between two shifts
      gap >= ValidationHelpers.CONSECUTIVE_SCHEDULE_THRESHOLD_IN_HOURS &&
      gap < Number(value)
  )
  const isError: boolean = !!gapsUnderMin.length
  const type = "min_gap"

  if (isError) {
    gapsUnderMin.forEach(([startScheduleId, finishScheduleId, gap]) => {
      errors.push({
        affected_schedules: [startScheduleId, finishScheduleId],
        error: {
          type: type,
          name: t(`name.${type}`),
          message: t(`message.${type}`, { min: value }),
          severity: severity,
          severity_fallback_sort: Number(value),
        },
      })
    })
  }

  return errors
}

export const validateMinLength = (
  value: ?string,
  schedule: ScheduleType,
  allSchedulesInCollection: Array<ScheduleType>,
  severity: ValidationSeverityType
): Array<ScheduleValidationType> => {
  const errors = []
  if (value == null) return errors

  const schedulesStats = allSchedulesInCollection.map((schedule) => HelperFunctions.getStatsFromSchedule(schedule))
  const totalWorkedHours = schedulesStats.reduce(
    (accumulator: number, schedulesStat) => accumulator + schedulesStat.worked_hours,
    0
  )

  const type = "min_length"

  allSchedulesInCollection.forEach((scheduleToValidate) => {
    const isError: boolean =
      scheduleToValidate.start != null && scheduleToValidate.finish != null && totalWorkedHours < Number(value)

    if (isError) {
      errors.push({
        schedule_id: scheduleToValidate.id,
        error: {
          type: type,
          name: t(`name.${type}`),
          message: t(`message.${type}`, { min: value }),
          severity: severity,
          severity_fallback_sort: Number(value),
        },
      })
    }
  })

  return errors
}

export const validateMaxLength = (
  value: ?(string | number),
  schedule: ScheduleType,
  allSchedulesInCollection: Array<ScheduleType>,
  severity: ValidationSeverityType
): Array<ScheduleValidationType> => {
  const errors = []
  if (value == null) return errors

  const schedulesStats = allSchedulesInCollection.map((schedule) => HelperFunctions.getStatsFromSchedule(schedule))
  const totalWorkedHours = schedulesStats.reduce(
    (accumulator: number, schedulesStat) => accumulator + schedulesStat.worked_hours,
    0
  )

  const type = "max_length"

  allSchedulesInCollection.forEach((scheduleToValidate) => {
    const isError: boolean =
      scheduleToValidate.start != null && scheduleToValidate.finish != null && totalWorkedHours > Number(value)

    if (isError) {
      errors.push({
        schedule_id: scheduleToValidate.id,
        error: {
          type: type,
          name: t(`name.${type}`),
          message: t(`message.${type}`, { max: value }),
          severity: severity,
          severity_fallback_sort: Number(value),
        },
      })
    }
  })

  return errors
}

export const validateMaxHoursWorkedIncludingBreaks = (
  value: ?number,
  schedulesWithSortedContiguous: Array<Array<ScheduleType>>,
  user: UserType,
  severity: ValidationSeverityType
): Array<RosterValidationType> => {
  const errors = []
  if (value == null) return errors

  const filteredSchedulesWithSortedContiguous = schedulesWithSortedContiguous.filter(
    (collection) => collection[0].start && collection[0].finish
  )
  const scheduleCollectionsByDay = _.groupBy(filteredSchedulesWithSortedContiguous, (collection) => collection[0].date)
  const daysExceedingMaxSpanOfHours = _.pickBy(scheduleCollectionsByDay, (collections, day) => {
    const timesOfSchedulesStartingOnDay = _.flattenDeep(collections).map((schedule) => ({
      start: HelperFunctions.dateTimeToMinutes(schedule.start, day),
      finish: HelperFunctions.dateTimeToMinutes(schedule.finish, day),
    }))

    const earliestScheduleStart = _.minBy(timesOfSchedulesStartingOnDay, (times) => times.start).start
    const latestScheduleFinish = _.maxBy(timesOfSchedulesStartingOnDay, (times) => times.finish).finish

    return (latestScheduleFinish - earliestScheduleStart) / 60 > value
  })

  const isError: boolean = !!_.keys(daysExceedingMaxSpanOfHours).length
  const type = "max_hours_worked_including_breaks"

  if (isError) {
    errors.push({
      affected_schedules: _.flattenDeep(_.values(daysExceedingMaxSpanOfHours)).map((schedule) => schedule.id),
      error: {
        type: type,
        name: t(`name.${type}`),
        message: t(`message.${type}`, { name: user.name, max: value }),
        severity: severity,
        severity_fallback_sort: value,
      },
    })
  }

  return errors
}

export const validateMaxShiftsInDay = (
  value: ?number,
  schedulesWithSortedContiguous: Array<Array<ScheduleType>>,
  user: UserType,
  severity: ValidationSeverityType
): Array<RosterValidationType> => {
  const errors = []
  if (value == null) return errors

  // don't run on vacant schedules with incomplete data so the error doesn't show up to early
  const filteredSchedulesWithSortedContiguous = schedulesWithSortedContiguous.filter(
    (collection) => collection[0].start && collection[0].finish
  )

  const scheduleCollectionsGroupedByDay: { [date: string]: Array<Array<ScheduleType>> } = _.groupBy(
    filteredSchedulesWithSortedContiguous,
    (collections) => collections[0].date
  )

  const daysWithTooManyShifts: Array<[string, Array<Array<ScheduleType>>]> = _.toPairs(
    scheduleCollectionsGroupedByDay
  ).filter(([date, scheduleCollectionsForDate]) => scheduleCollectionsForDate.length > value)
  const isError: boolean = !!daysWithTooManyShifts.length
  const type = "max_shifts_in_day"

  if (isError) {
    daysWithTooManyShifts.forEach(([date, scheduleCollectionsForDate]) => {
      errors.push({
        affected_schedules: _.flatten(
          scheduleCollectionsForDate.map((collection) => collection.map((schedule) => schedule.id))
        ),
        error: {
          type: type,
          name: t(`name.${type}`),
          message: t(`message.${type}`, { name: user.name, max: value }),
          severity: severity,
          severity_fallback_sort: value,
        },
      })
    })
  }

  return errors
}

export const validateMaxShiftsInWeek = (
  value: ?number,
  schedulesWithSortedContiguous: Array<Array<ScheduleType>>,
  user: UserType,
  severity: ValidationSeverityType
): Array<RosterValidationType> => {
  const errors = []
  if (value == null) return errors

  const type = "max_shifts_in_week"

  const daysRostered: Array<string> = _.uniq(_.map(schedulesWithSortedContiguous, (collections) => collections[0].date))
  const isError: boolean = daysRostered.length > value
  if (isError) {
    errors.push({
      affected_schedules: _.flatten(
        schedulesWithSortedContiguous.map((collection) => collection.map((schedule) => schedule.id))
      ),
      error: {
        type: type,
        name: t(`name.${type}`),
        message: t(`message.${type}`, {
          name: user.name,
          num: daysRostered.length,
          max: Math.round(value),
        }),
        severity: severity,
        severity_fallback_sort: daysRostered.length,
      },
    })
  }

  return errors
}

/**
 * Max hours in day validation looks at schedules in a 24 hr period: 00:00 - 24:00.
 * Hours in overnight schedule blocks are split out between the two days they span.
 * Ex: Schedule date: 10/31/21, schedule starts: 20:00, schedule ends: 02:00.
 * 10/31/21 gets 4 hrs, 11/1/21 gets 2 hrs
 *
 * Current implementation details:
 * - Only deducts unpaid breaks, splits if times are present, uses length if not (see below)
 * - If there are shift clashes, those hours are included in the max hours per day count
 */
export const validateMaxHoursInDay = (
  value: ?number,
  schedules: Array<ScheduleType>,
  user: UserType,
  severity: ValidationSeverityType
): Array<RosterValidationType> => {
  const errors = []
  if (value == null) return errors

  const rosteredDays = _.uniq(schedules.map((s) => s.date))
  const schedulesByDay = rosteredDays.map((date) => ({
    date: date,
    schedules: schedules.filter((schedule) => {
      if (schedule.start == null || schedule.finish == null) return false

      const startDate = HelperFunctions.dateTimeToDate(schedule.start)
      const finishDate = HelperFunctions.dateTimeToDate(schedule.finish)
      const finishMinute = HelperFunctions.dateTimeToMinutes(schedule.finish || "", date)

      return startDate === date || (finishDate === date && finishMinute !== 0)
    }),
  }))

  const daysExceedingMaxHours = schedulesByDay.filter(({ date, schedules }) => {
    const totalHours = schedules.reduce(
      (accumulator: [number, number], currentSchedule) => {
        const dateAfter = moment(date, C.DATE_FMT).add(1, "days").format(C.DATE_FMT)

        const scheduledMinutes = [
          HelperFunctions.totalMinutesOnDate(currentSchedule, date),
          HelperFunctions.totalMinutesOnDate(currentSchedule, dateAfter), // overnight hours
        ]

        const deductibleBreaks = currentSchedule.schedule_breaks.reduce(
          (accumulator: [number, number], schedule_break) => {
            if (schedule_break.paid) return accumulator

            if ((schedule_break.start === "" || schedule_break.finish === "") && currentSchedule.date === date) {
              // if only the break length exists, we assume it's on this day if the schedule starts on this day
              accumulator[0] += schedule_break.length
            } else if (schedule_break.start && schedule_break.finish) {
              accumulator[0] += HelperFunctions.totalMinutesOnDate(schedule_break, date)
              accumulator[1] += HelperFunctions.totalMinutesOnDate(schedule_break, dateAfter)
            }

            return accumulator
          },
          [0, 0]
        )

        accumulator[0] += (scheduledMinutes[0] - deductibleBreaks[0]) / 60
        accumulator[1] += (scheduledMinutes[1] - deductibleBreaks[1]) / 60

        return accumulator
      },
      [0, 0]
    )

    return totalHours[0] > value || totalHours[1] > value
  })

  const schedulesToFlag = _.flatten(daysExceedingMaxHours.map((day) => day.schedules))
  const isError: boolean = !!schedulesToFlag.length

  if (isError) {
    errors.push({
      affected_schedules: _.uniq(schedulesToFlag.map((schedule) => schedule.id)),
      error: {
        type: "max_hours_in_day",
        name: t("name.max_hours_in_day"),
        message: t("message.max_hours_in_day", { name: user.name, max: value }),
        severity: severity,
        severity_fallback_sort: value,
      },
    })
  }

  return errors
}

export const validateMaxHoursInWeekForAwardable = (
  value: ?number,
  single_schedule: ScheduleType,
  schedules_for_week: Array<ScheduleType>,
  user: UserType,
  severity: ValidationSeverityType,
  warning_info: ?Array<string>
): Array<ScheduleValidationType> => {
  const errors = []
  if (value == null) return errors

  const schedulesStats = HelperFunctions.getStatsFromSchedules(schedules_for_week, {}, [], {})
  const isError: boolean = schedulesStats.total_hours > value

  const maxHoursInWeekMessage = (warn_data) => {
    if (!warn_data || warn_data[0] === "default") {
      return t("message.max_hours_in_week", {
        name: user.name,
        max: value,
        actual: schedulesStats.total_hours,
      })
    }
    return t("message.max_hours_in_week_non_standard_warning", {
      name: user.name,
      max: value,
      actual: schedulesStats.total_hours,
      start_day: warn_data[0],
      start_date: warn_data[1],
      end_date: warn_data[2],
    })
  }

  if (isError) {
    errors.push({
      schedule_id: single_schedule.id,
      error: {
        type: "max_hours_in_week",
        name: t(`name.max_hours_in_week`),
        message: maxHoursInWeekMessage(warning_info),
        severity: severity,
        severity_fallback_sort: value,
      },
    })
  }
  return errors
}
