// @flow

import moment from "moment"
import _ from "lodash"
import * as C from "rosters/WebpackRosters/consts"
import * as Types from "../types"
import * as Constants from "./constants"
import * as HelperFuncs from "./functions"

const WEEK_START_DAY = 1

export const getDailyScheduleId = (date: moment, template_id?: ?number): number =>
  // We use the 5th as it is the first monday since epoch
  date.diff(moment("1970-01-05", C.DATE_FMT), "days") + (template_id || 0) * 1000000

export const getRosterId = (date: moment, template_id?: ?number): number =>
  // We use the 5th as it is the first monday since epoch
  date.diff(moment("1970-01-05", C.DATE_FMT), "weeks") + (template_id || 0) * 1000000

export const getDailySchedules = (
  start: string,
  finish: string,
  template_id?: ?number
): Array<Types.DailyScheduleType> => {
  const dates = HelperFuncs.getAllDatesFromStr(start, finish)
  return dates.map((date: moment) => ({
    created_at: "2018-12-09 12:24:43",
    date: date.format(C.DATE_FMT),
    id: getDailyScheduleId(date, template_id),
    roster_id: getRosterId(date, template_id),
    updated_at: "2018-12-09 12:24:43",
  }))
}

export const getRosters = (start: string, finish: string, template_id?: ?number): Array<Types.RosterRubyType> => {
  const all_dates = HelperFuncs.getAllDatesFromStr(start, finish)
  const start_dates = _.uniq(all_dates.map((date) => date.isoWeekday(WEEK_START_DAY).format(C.DATE_FMT)))
  const week_dates = start_dates.map((d) => moment(d, C.DATE_FMT))
  return week_dates.map((date: moment) => ({
    created_at: "2000-12-09 12:24:43",
    end: date.clone().add(6, "days").format(C.DATE_FMT),
    fixed_costs: 0,
    id: getRosterId(date, template_id),
    lockdown_time: null,
    lockdown_user_id: null,
    organisation_id: 1,
    roster_template_id: null,
    start: date.format(C.DATE_FMT),
    template: false,
    updated_at: "2000-12-23 19:37:07",
  }))
}

let scheduleId = 1
export const getNewScheduleId = (): number => {
  scheduleId = scheduleId + 1
  return scheduleId
}

export const getSchedules = (start: string, finish: string): Array<Types.ScheduleType> => {
  const all_dates = HelperFuncs.getAllDatesFromStr(start, finish)
  const scheds = _.flatten(
    all_dates.map((date) => {
      const ds_id = getDailyScheduleId(date)
      const dow = date.isoWeekday()
      const date_str = date.format(C.DATE_FMT)
      const maybeScheds: Array<?Types.ScheduleType> = [
        dow === 5 || dow === 6
          ? null
          : {
              ...Constants.DEFAULT_SCHEDULE,
              department_id: TEAM.in_store.id,
              user_id: USER.manager_larry.id,
              daily_schedule_id: ds_id,
              date: date_str,
              start: date_str + " 08:30:00",
              finish: date_str + " 12:30:00",
            },
        dow === 5 || dow === 6
          ? null
          : {
              ...Constants.DEFAULT_SCHEDULE,
              department_id: TEAM.manager.id,
              user_id: USER.manager_larry.id,
              daily_schedule_id: ds_id,
              date: date_str,
              start: date_str + " 12:30:00",
              finish: date_str + " 15:30:00",
            },
        dow === 2 || dow === 3
          ? null
          : {
              ...Constants.DEFAULT_SCHEDULE,
              department_id: ds_id % 2 ? TEAM.cashier.id : TEAM.in_store.id,
              user_id: USER.employee_jeff.id,
              daily_schedule_id: ds_id,
              date: date_str,
              start: date_str + " 08:30:00",
              finish: date_str + " 14:30:00",
            },
        dow === 5 || dow === 6
          ? null
          : {
              ...Constants.DEFAULT_SCHEDULE,
              department_id: ds_id % 2 ? TEAM.noosa.id : TEAM.back_of_house.id,
              user_id: USER.employee_multi_loc.id,
              daily_schedule_id: ds_id,
              date: date_str,
              start: date_str + " 08:30:00",
              finish: date_str + " 14:30:00",
            },
      ]
      const scheds: Array<Types.ScheduleType> = maybeScheds.filter(Boolean)
      return scheds.map((s, i) => ({ ...s, id: getNewScheduleId() }))
    })
  )
  return scheds
}

export const getLeaveRequests = (start: string, finish: string): Array<Types.LeaveRequestRubyType> => {
  const all_dates = HelperFuncs.getAllDatesFromStr(start, finish)
  return _.flatten(
    all_dates.map((date) => {
      const ds_id = getDailyScheduleId(date)
      const date_str = date.format(C.DATE_FMT)
      if (date.isoWeekday() !== 6) {
        return []
      }
      return [
        {
          ...Constants.DEFAULT_LEAVE,
          user_id: USER.manager_larry.id,
          start: date_str,
          start_time: date_str + " 01:00:00",
          finish: date_str,
          leave_type: "Personal/Carer's Leave",
          reason: "Sick :(",
          finish_time: date_str + " 12:00:00",
        },
      ].map((s, i) => ({ ...s, id: ds_id * 1000 + i }))
    })
  )
}

export const getUnavailabilities = (start: string, finish: string): Array<Types.UnavailabilityRubyType> => {
  const all_dates = HelperFuncs.getAllDatesFromStr(start, finish)
  return _.flatten(
    all_dates.map((date) => {
      const date_str = date.format(C.DATE_FMT)
      if (date.isoWeekday() !== 6) {
        return []
      }
      return [
        {
          ...Constants.DEFAULT_UNAVAILABILITY,
          user_id: USER.employee_jeff.id,
          user_name: USER.employee_jeff.name,
          start: date_str + " 01:00:00",
          end: date_str + " 12:00:00",
          title: "busy",
        },
      ]
    })
  )
}

// Define a variety of award tags for testing
const default_award_tags = [
  "5th Public Holiday Worked In the Calendar Year",
  "Salaried",
  "Paid Training",
  "Shift Supervisor",
  "General Employee",
  "Introductory Employee",
  "Public Holiday Not Worked",
  "Part Time",
  "5th Public Holiday Worked In the Calandar Year",
  "Full Time",
]

const USER = {
  manager_larry: {
    ...Constants.DEFAULT_USER,
    id: 10,
    name: "Manager Larry",
  },
  employee_jeff: {
    ...Constants.DEFAULT_USER,
    id: 11,
    name: "Employee Jeff",
  },
  employee_with_award_tags: {
    ...Constants.DEFAULT_USER,
    id: 12,
    name: "Employee Charlie",
    award_tags: default_award_tags,
  },
  employee_multi_loc: {
    ...Constants.DEFAULT_USER,
    id: 13,
    name: "Employee Multi Loc",
  },
}

export const USERS: Array<Types.UserType> = _.values(USER)

const LOCATION: { [name: string]: Types.LocationRubyType } = {
  qsm: {
    ...Constants.DEFAULT_LOCATION,
    name: "Queen Street Mall",
    id: 1,
  },
  noosa: {
    ...Constants.DEFAULT_LOCATION,
    name: "Noosa",
    id: 2,
  },
}

const LOCATIONS: Array<Types.LocationRubyType> = _.values(LOCATION)

export const AWARDS: Array<Types.AwardType> = []
export const ALLOWANCES: Array<Types.AllowanceType> = []

const TEAM = {
  in_store: {
    ...Constants.DEFAULT_TEAM,
    location_id: LOCATION.qsm.id,
    name: "In Store",
    colour: "#ff5f3a",
    short_name: "IS",
    id: 100,
  },
  manager: {
    ...Constants.DEFAULT_TEAM,
    location_id: LOCATION.qsm.id,
    name: "Manager",
    colour: "#c7e685",
    short_name: "M",
    id: 101,
  },
  cashier: {
    ...Constants.DEFAULT_TEAM,
    location_id: LOCATION.qsm.id,
    name: "Cashier",
    colour: "#86d4cd",
    short_name: "C",
    id: 102,
  },
  back_of_house: {
    ...Constants.DEFAULT_TEAM,
    location_id: LOCATION.qsm.id,
    name: "Back of House",
    colour: "#409be1",
    short_name: "BoH",
    id: 103,
  },
  noosa: {
    ...Constants.DEFAULT_TEAM,
    location_id: LOCATION.noosa.id,
    name: "Noosa Team",
    colour: "#157f65",
    short_name: "No",
    id: 104,
  },
}

const ONCOST_CONFIG = {
  brisbane_ten_percent: {
    config_name: "Brisbane",
    config_value: 10,
    employee_tags: default_award_tags,
    location_tags: [LOCATION.qsm.name],
    pension_fund: false,
  },
  noosa_zero_percent: {
    config_name: "Noosa",
    config_value: 0,
    employee_tags: default_award_tags,
    location_tags: [LOCATION.noosa.name],
    pension_fund: false,
  },
}

const ONCOST_CONFIGS: Array<Types.OncostConfigurationType> = _.values(ONCOST_CONFIG)

const deps_users_can_work_in: Array<Types.UserToDepartmentMap> = [
  {
    user_id: USER.manager_larry.id,
    team_id: TEAM.manager.id,
  },
  {
    user_id: USER.manager_larry.id,
    team_id: TEAM.in_store.id,
  },
  {
    user_id: USER.employee_jeff.id,
    team_id: TEAM.in_store.id,
  },
  {
    user_id: USER.employee_jeff.id,
    team_id: TEAM.cashier.id,
  },
  {
    user_id: USER.employee_multi_loc.id,
    team_id: TEAM.back_of_house.id,
  },
  {
    user_id: USER.employee_multi_loc.id,
    team_id: TEAM.noosa.id,
  },
]

const TEAMS: Array<Types.TeamRubyType> = _.values(TEAM)

export const STAT_TYPES: Array<Types.StatType> = [
  {
    stat_type: "sales",
    label: "sales",
    format: "currency",
    data_type: "sum",
    id: 1,
  },
  {
    stat_type: "headCount",
    label: "headCount",
    format: "whole_number",
    data_type: "sum",
    id: 2,
  },
  {
    stat_type: "pizza",
    label: "pizza",
    format: "%{whole_number}P",
    data_type: "sum",
    id: 3,
  },
]

const DATA_STREAMS: Array<Types.DataStreamRubyType> = [
  {
    data_interval: 900,
    default_stat_type_id: 1,
    id: 1,
    name: "Sales",
    source: "api",
  },
  {
    data_interval: 900,
    default_stat_type_id: 2,
    id: 2,
    name: "Head Counts",
    source: "api",
  },
  {
    data_interval: 900,
    default_stat_type_id: 3,
    id: 3,
    name: "Pizzas",
    source: "api",
  },
]

const DATA_STREAM_JOINS: Array<Types.DataStreamJoinRubyType> = [
  {
    data_stream_id: 1,
    data_streamable_id: LOCATION.qsm.id,
    data_streamable_type: "Location",
    head_count_map: null,
    id: 1,
    rostering_ratio: 15,
    stat_type_id: 1,
    stat_types: "sales",
  },
  {
    data_stream_id: 2,
    data_streamable_id: LOCATION.qsm.id,
    data_streamable_type: "Location",
    head_count_map: null,
    id: 2,
    rostering_ratio: 15,
    stat_type_id: 2,
    stat_types: "headCount",
  },
  {
    data_stream_id: 3,
    data_streamable_id: LOCATION.qsm.id,
    data_streamable_type: "Location",
    head_count_map: null,
    id: 3,
    rostering_ratio: 15,
    stat_type_id: 3,
    stat_types: "pizza",
  },
]

export const SHIFT_SLOT: {|
  afternoon: Types.ShiftSlotType,
  double: Types.ShiftSlotType,
  morning: Types.ShiftSlotType,
  night: Types.ShiftSlotType,
|} = {
  morning: {
    ...Constants.DEFAULT_SHIFT_SLOT,
    id: 1000,
    starting_before: 9,
    starting_after: null,
    ending_before: null,
    ending_after: null,
    sort_order: 1,
    name: "Morning",
  },
  afternoon: {
    ...Constants.DEFAULT_SHIFT_SLOT,
    id: 1001,
    starting_before: 17,
    starting_after: 9,
    ending_before: null,
    ending_after: null,
    sort_order: 2,
    name: "Afternoon",
  },
  night: {
    ...Constants.DEFAULT_SHIFT_SLOT,
    id: 1002,
    starting_before: 23,
    starting_after: 16,
    ending_before: null,
    ending_after: null,
    sort_order: 3,
    name: "Night",
  },
  double: {
    ...Constants.DEFAULT_SHIFT_SLOT,
    id: 1003,
    starting_before: 17,
    starting_after: null,
    ending_before: null,
    ending_after: 22,
    sort_order: 4,
    name: "Double Shifts",
  },
}

export const SHIFT_SLOTS: Array<Types.ShiftSlotType> = _.values(SHIFT_SLOT)

export const INITIAL_PROPS: Types.TransformedRubyStateType = {
  ...Constants.DEFAULT_TRANSFORMED_PROPS,
  stat_types: STAT_TYPES,
  data_streams: DATA_STREAMS,
  data_stream_joins: DATA_STREAM_JOINS,
  config: {
    ...Constants.DEFAULT_TRANSFORMED_PROPS.config,
    shift_claiming_enabled: true,
    enable_full_location_rosters: true,
    enable_shift_acceptance: true,
    enable_secondary_departments: false,
    enable_staff_type_filter: false,
    sms_enabled: true,
    employee_custom_validations_map: {},
    labour_budget_enabled: true,
    pay_period_length: 14,
    roster_week_start_day: 1,
    shift_slots: SHIFT_SLOTS,
    recent_pay_period_start: moment("2019-01-01", C.DATE_FMT),
    using_ppt_acceptance: true,
    managed_team_ids: [...TEAMS.map((t) => t.id), Constants.DEFAULT_TEAM.id],
    publishable_team_ids: [],
    visible_user_ids: [],
    permissions: {
      can_create_leave: true,
      can_approve_leave: true,
      can_approve_own_leave: true,
      can_update_leave: true,
      can_create_timesheets: true,
      can_update_shift_proposal: true,
      can_publish_rosters: true,
      can_create_schedule: true,
      can_update_schedule: true,
      can_lock_rosters: true,
      can_import_ratios: true,
      can_update_open_hour_periods: true,
      can_view_projections_table_configuration: true,
      can_update_projections_table_configuration: true,
      roster_templates: {
        create: true,
        delete: true,
        read: true,
        update: true,
      },
    },
    organisation: {
      country: "Australia",
      enable_predictive_headcounts: false,
      forecasting_strategy: Constants.AVERAGE_OF_DATES,
      one_click_schedule: false,
      id: 100,
    },
    deps_users_can_work_in: deps_users_can_work_in,
    business_hours: [
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 20 * 60,
        id: 1,
        location_id: LOCATION.qsm.id,
        start: 8 * 60,
        weekday: 0,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 20 * 60,
        id: 2,
        location_id: LOCATION.qsm.id,
        start: 10 * 60,
        weekday: 1,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 20 * 60,
        id: 3,
        location_id: LOCATION.qsm.id,
        start: 8 * 60,
        weekday: 2,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 28 * 60,
        id: 4,
        location_id: LOCATION.qsm.id,
        start: 8 * 60,
        weekday: 3,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 20 * 60,
        id: 5,
        location_id: LOCATION.qsm.id,
        start: 4 * 60,
        weekday: 4,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 12 * 60,
        id: 6,
        location_id: LOCATION.qsm.id,
        start: 8 * 60,
        weekday: 5,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 20 * 60,
        id: 7,
        location_id: LOCATION.qsm.id,
        start: 14 * 60,
        weekday: 5,
      },
      {
        ...Constants.DEFAULT_BUSINESS_HOURS,
        finish: 14 * 60,
        id: 8,
        location_id: LOCATION.qsm.id,
        start: 8 * 60,
        weekday: 6,
      },
    ],
  },
  oncost_configurations: ONCOST_CONFIGS,
  locations: LOCATIONS,
  teams: TEAMS,
  users: USERS,
  qualifications: [
    {
      id: 1,
      name: "Visa 407",
      organisation_id: 1,
      created_at: "2018-10-10 12:59:19",
      updated_at: "2018-10-10 12:59:19",
      maximum_hours: 20.5,
      link_quals_and_departments: false,
      block_roster_publishing: false,
      block_roster_publishing_outside_effective_dates: true,
      max_hours_period: "weekly",
    },
    {
      id: 2,
      name: "QUAL WITH NO MAX HOURS",
      organisation_id: 1,
      created_at: "2018-10-10 12:59:19",
      updated_at: "2018-10-10 12:59:19",
      maximum_hours: null,
      link_quals_and_departments: false,
      block_roster_publishing: false,
      block_roster_publishing_outside_effective_dates: true,
      max_hours_period: "weekly",
    },
    {
      id: 3,
      name: "QUAL WITH NO MAX HOURS (Zero Hours)",
      organisation_id: 1,
      created_at: "2018-10-10 12:59:19",
      updated_at: "2018-10-10 12:59:19",
      maximum_hours: 0,
      link_quals_and_departments: false,
      block_roster_publishing: false,
      block_roster_publishing_outside_effective_dates: true,
      max_hours_period: "weekly",
    },
  ],
}

export const createEmptyContext = (props: Types.TransformedRubyStateType): Types.GlobalState => ({
  rule_sets: props.rule_sets,
  labour_budgets: props.labour_budgets,
  sales_budgets: props.sales_budgets,
  metric_budgets: props.metric_budgets,
  budget_config: props.budget_config,
  new_labour_budgets_enabled: props.new_labour_budgets_enabled,
  enable_automatic_breaks: props.enable_automatic_breaks,
  custom_events: Constants.DEFAULT_STATE.custom_events,
  comments: Constants.DEFAULT_STATE.comments,
  display_message: Constants.DEFAULT_STATE.display_message,
  cache: Constants.DEFAULT_STATE.cache,
  oncost_configurations: props.oncost_configurations,
  data_stream_joins: props.data_stream_joins,
  head_count_maps: [],
  data_streams: props.data_streams,
  validations: Constants.DEFAULT_STATE.validations,
  history: Constants.DEFAULT_STATE.history,
  static: {
    teams: props.teams,
    archived_teams: props.teams,
    team_groups: props.team_groups,
    users: props.users,
    user_qualifications: props.user_qualifications,
    department_qualifications: props.department_qualifications,
    qualifications: props.qualifications,
    locations: props.locations,
    departments: props.teams,
    shift_details: props.shift_details,
    position_groups: props.position_groups,
    positions: props.positions,
    training_logs: props.training_logs,
  },
  focus: Constants.DEFAULT_STATE.focus,
  stat_types: props.stat_types,
  modal: Constants.DEFAULT_STATE.modal,
  cognitive: Constants.DEFAULT_STATE.cognitive,
  config: {
    business_hours: props.config.business_hours,
    can_create_paid_meal_breaks: props.config.can_create_paid_meal_breaks,
    current_user_id: props.config.current_user_id,
    public_holidays: { all: [] },
    permissions: props.config.permissions,
    enable_roster_validation: props.config.enable_roster_validation,
    enable_task_based_rostering: props.config.enable_task_based_rostering,
    enable_staff_type_filter: props.config.enable_staff_type_filter,
    default_validation_settings: props.config.default_validation_settings,
    default_validation_field_settings: props.config.default_validation_field_settings,
    custom_validation_settings: props.config.custom_validation_settings,
    deps_users_can_work_in: props.config.deps_users_can_work_in,
    labour_budget_enabled: false,
    employee_custom_validations_map: props.config.employee_custom_validations_map,
    managed_team_ids: props.config.managed_team_ids,
    publishable_team_ids: props.config.publishable_team_ids,
    visible_user_ids: props.config.visible_user_ids,
    pay_period_length: props.config.pay_period_length,
    platform_model: props.config.platform_model,
    shift_slots: _.values(SHIFT_SLOT),
    recent_pay_period_start: props.config.recent_pay_period_start,
    roster_week_start_day: props.config.roster_week_start_day,
    shift_claiming_enabled: props.config.shift_claiming_enabled,
    shift_swapping_enabled: props.config.shift_swapping_enabled,
    week_start_day: props.config.recent_pay_period_start.weekday(),
    enable_shift_acceptance: props.config.enable_shift_acceptance,
    enable_secondary_departments: props.config.enable_secondary_departments,
    sms_enabled: props.config.sms_enabled,
    enable_full_location_rosters: props.config.enable_full_location_rosters,
    show_weekends: props.config.show_weekends,
    organisation: props.config.organisation,
    using_ppt_acceptance: props.config.using_ppt_acceptance,
    allowances: [],
    awards: [],
  },
  settings: {
    day_range: 14,
    start_date: moment("2019-01-01", C.DATE_FMT),
    finish_date: moment("2019-01-14", C.DATE_FMT),
    previous_finish_date: null,
    previous_start_date: null,
    print_mode: false,
    template_id: null,
    selected_location_ids: [LOCATION.qsm.id],
    selected_award_tags: [],
    selected_shift_details: [],
    selected_staff_types: [],
    selected_team_ids: [TEAM.in_store.id, TEAM.manager.id, TEAM.cashier.id, TEAM.back_of_house.id],
    view: "rosters",
    visible_hours: {
      finish_half_hour: 60,
      start_half_hour: 10,
    },
    user_sort_order: {},
  },
  loading: Constants.DEFAULT_STATE.loading,
  view_options: {
    ...Constants.DEFAULT_STATE.view_options,
    roster_view: "stacked",
    key_stats: {
      stat_1: "total_hours",
      stat_2: "total_schedule_costs",
      stat_3: "total_schedules",
    },
    prev_schedule_sort: "start_time",
    schedule_sort: "custom",
    schedule_sort_lock: false,
  },
  schedules: Constants.DEFAULT_STATE.schedules,
  schedule_swap_plans: Constants.DEFAULT_STATE.schedule_swap_plans,
  shifts: Constants.DEFAULT_STATE.shifts,
  published_schedules: Constants.DEFAULT_STATE.published_schedules,
  aics: Constants.DEFAULT_STATE.aics,
  time_off: Constants.DEFAULT_STATE.time_off,
  staged: Constants.DEFAULT_STATE.staged,
  rosters: {
    rosters: [],
    user_daily_schedule_joins: [],
    daily_schedules: [],
  },
  roster_patterns: {
    roster_pattern_schedules: props.roster_pattern_schedules,
    roster_pattern_rdos: props.roster_pattern_rdos,
    roster_pattern_user_joins: props.roster_pattern_user_joins,
    roster_patterns: props.roster_patterns,
  },
  rdos: Constants.DEFAULT_STATE.rdos,
  contractRDOs: Constants.DEFAULT_STATE.rdos,
  demand_data: Constants.DEFAULT_STATE.demand_data,
  templates: props.templates,
  template_user_joins: props.template_user_joins,
  sales_targets: [],
  weather: [],
  employment_condition_sets: Constants.DEFAULT_STATE.employment_condition_sets,
})

export const INITIAL_STATE: Types.GlobalState = createEmptyContext(INITIAL_PROPS)
