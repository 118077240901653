// @flow

import _ from "lodash"
import moment from "moment"
import * as C from "rosters/WebpackRosters/consts"
import type { ShiftSummary } from "time_off/Modal/types"

import type {
  GlobalState,
  ScheduleType,
  ShiftType,
  LeaveRequestRubyType,
  CustomValidationObject,
  ValidationSeverityType,
  ValidationErrorType,
  RosterValidationType,
  ScheduleValidationType,
  UnavailabilityRubyType,
} from "../../types"
import * as Constants from "../constants"
import * as HelperFunctions from "../functions"
import * as Selectors from "../selectors"

export const COMMON_BREAKS_VALIDATION_TYPE = "breaks_not_matching_rules"
const ALL_BREAKS_VALIDATION_TYPES = [
  "paid_breaks_not_matching",
  "unpaid_breaks_not_matching",
  "breaks_start_before_surpassed_hours_not_matching",
  "breaks_not_in_bounds",
]

export const COMMON_QUALIFICATION_VALIDATION_TYPE = "qualification_invalid"
const ALL_QUALIFICATION_VALIDATION_TYPES = [
  "user_qualification_expired",
  "user_qualification_no_current_effective_dates",
  "user_qualification_unapproved",
  "qualification_max_hours_in_week_exceeded",
  "user_missing_required_team_qualification",
]

export const INCOMPLETE_TRAINING_VALIDATION_TYPE = "incomplete_training"

export const CONSECUTIVE_SCHEDULE_THRESHOLD_IN_MINUTES = 60
export const CONSECUTIVE_SCHEDULE_THRESHOLD_IN_HOURS = CONSECUTIVE_SCHEDULE_THRESHOLD_IN_MINUTES / 60

type GenericValidationType = { error: ValidationErrorType }

export const getSeverityValue = (severity: ValidationSeverityType): ?number => {
  switch (severity) {
    case "warning":
      1
      break
    case "blocking":
      2
      break
    default:
      0
  }
}

export const taskBasedRosteringIsEnabled = (state: GlobalState): boolean => state.config.enable_task_based_rostering

export const validationTypeIsEnabled = (state: GlobalState, validationType: string): boolean => {
  if (ALL_BREAKS_VALIDATION_TYPES.includes(validationType)) {
    validationType = COMMON_BREAKS_VALIDATION_TYPE
  } else if (ALL_QUALIFICATION_VALIDATION_TYPES.includes(validationType)) {
    validationType = COMMON_QUALIFICATION_VALIDATION_TYPE
  }

  const validationSetting = state.config.default_validation_settings[validationType]
  return (validationSetting && validationSetting.enabled) || false
}

export const validationTypeIsBlocking = (state: GlobalState, validationType: string): boolean => {
  if (ALL_BREAKS_VALIDATION_TYPES.includes(validationType)) {
    validationType = COMMON_BREAKS_VALIDATION_TYPE
  }

  const validationSetting = state.config.default_validation_settings[validationType]
  return (
    validationSetting && validationTypeIsEnabled(state, validationType) && validationSetting.block_roster_publishing
  )
}

export const defaultRuledValidationsAreBlocking = (state: GlobalState): boolean =>
  state.config.default_validation_field_settings.block_roster_publishing

export const getCustomValidationSettingsApplicableToSchedule = (
  state: GlobalState,
  schedule: ScheduleType,
  user_id: number
): Array<CustomValidationObject> => {
  const custom_settings_ids = state.config.employee_custom_validations_map[String(user_id)] || []
  const custom_settings: Array<CustomValidationObject> = custom_settings_ids
    .map((id) => state.config.custom_validation_settings[String(id)])
    .filter((cs) => cs != null)

  const date: moment = moment(schedule.date, C.DATE_FMT)

  const team = Selectors.teamByID(state)[String(schedule.department_id)] || Constants.DEFAULT_TEAM
  const schedule_location = Selectors.locationByID(state)[String(team.location_id)]

  const applicable_custom_settings: Array<CustomValidationObject> = custom_settings
    .filter((cs) => {
      const date_ranges: Array<{ endDate: ?string, startDate: ?string }> = _.values(cs.date_filters.date_ranges)
      const date_ranges_apply =
        !date_ranges.length ||
        _.some(
          date_ranges.map(
            (dr) =>
              (dr.startDate == null || date.isAfter(dr.startDate)) && (dr.endDate == null || date.isBefore(dr.endDate))
          )
        )

      const applicable_day_indexes: Array<number> = cs.date_filters.selected_days
      const days_apply = _.isEmpty(applicable_day_indexes) || applicable_day_indexes.includes(date.isoWeekday() % 7)

      return date_ranges_apply && days_apply
    })
    .filter((cs) => {
      if (!cs.filters.location_ids.length) {
        // if rule has no location IDs on it then it applies always
        return true
      }

      if (!schedule_location) {
        // if rule HAS location IDs, it can't apply to schedules without a location
        return false
      }

      return cs.filters.location_ids.includes(schedule_location.id)
    })

  return applicable_custom_settings
}

/**
 * E.g. "Too many shifts on the same day" -> "Too many shifts on the same day (Custom Setting 3)"
 */
export const addCustomSettingNameToValidationMessage = (message: string, name?: ?string): string =>
  name != null && name !== "" ? `${message} (${name})` : message

// eslint-disable-next-line flowtype/no-weak-types
const getMostSevereCustomValidationOfSingleType = (validations: Array<GenericValidationType>, type: string): any => {
  if (!validations.length) {
    return validations
  }

  if (_.uniq(_.map(validations, (v) => getSeverityValue(v.error.severity))).length === 1) {
    // all severities the same, get the smallest validation value
    return _.minBy(validations, (v) => v.error.severity_fallback_sort)
  } else {
    return _.maxBy(validations, (v) => getSeverityValue(v.error.severity))
  }
}

export const getMostSevereCustomValidationOfEachScheduleValidationType = (
  customValidations: Array<ScheduleValidationType>
): Array<ScheduleValidationType> =>
  _.flatMap(
    _.groupBy(customValidations, (validation) => validation.error.type),
    (validations, type) => getMostSevereCustomValidationOfSingleType(validations, type)
  )

export const getMostSevereCustomValidationOfEachRosterValidationType = (
  customValidations: Array<RosterValidationType>
): Array<RosterValidationType> =>
  _.flatMap(
    _.groupBy(customValidations, (validation) => validation.error.type),
    (validations, type) => getMostSevereCustomValidationOfSingleType(validations, type)
  )

export const findNextContiguousSchedule = (
  currentSchedule: ScheduleType,
  otherSchedules: Array<ScheduleType>
): ?ScheduleType => {
  const currentScheduleFinish = HelperFunctions.dateTimeToMinutes(
    currentSchedule.finish || " 23:59:00",
    currentSchedule.date
  )

  const referenceDate = currentSchedule.date
  const nextContiguousSchedule = otherSchedules.find((otherSchedule) => {
    const otherScheduleStart = HelperFunctions.dateTimeToMinutes(otherSchedule.start || " 00:00:00", referenceDate)

    const minThreshold = currentScheduleFinish
    const maxThreshold = currentScheduleFinish + CONSECUTIVE_SCHEDULE_THRESHOLD_IN_MINUTES

    return (
      currentScheduleFinish != null &&
      otherScheduleStart != null &&
      otherScheduleStart >= minThreshold &&
      otherScheduleStart < maxThreshold
    )
  })

  return nextContiguousSchedule
}

export const findPreviousContiguousSchedule = (
  schedule: ScheduleType,
  otherSchedules: Array<ScheduleType>
): ?ScheduleType => {
  const scheduleStart = HelperFunctions.dateTimeToMinutes(schedule.start || " 00:00:00", schedule.date)

  return otherSchedules.find((otherSchedule) => {
    const referenceDate = schedule.date
    const otherScheduleFinish = HelperFunctions.dateTimeToMinutes(otherSchedule.finish || " 23:59:00", referenceDate)

    const minThreshold = scheduleStart - CONSECUTIVE_SCHEDULE_THRESHOLD_IN_MINUTES
    const maxThreshold = scheduleStart

    return (
      scheduleStart != null &&
      otherSchedule.finish != null &&
      otherScheduleFinish > minThreshold &&
      otherScheduleFinish <= maxThreshold
    )
  })
}

export const sortContiguousSchedules = (
  firstSchedule: ScheduleType,
  contiguousSchedules: Array<ScheduleType>
): Array<ScheduleType> =>
  contiguousSchedules.sort(
    (scheduleA, scheduleB) =>
      HelperFunctions.dateTimeToMinutes(scheduleA.start || " 00:00:00", firstSchedule.date) -
      HelperFunctions.dateTimeToMinutes(scheduleB.start || " 00:00:00", firstSchedule.date)
  )

export const validateUnavailabilityEndDate = function (unavailability: UnavailabilityRubyType): moment {
  const unavailabilityRawEndDate = moment(unavailability.end, C.DATE_TIME_FMT)
  if (unavailability.all_day) {
    // all day unavailability ends at midnight, so we need to remove one day to get its actual 'finish' date
    return unavailabilityRawEndDate.subtract(1, "day")
  } else {
    if (unavailabilityFinishesAtMidnight(unavailabilityRawEndDate)) {
      // if specified unavailability ends at midnight, we need to add one day to get its actual 'finish' date
      return unavailabilityRawEndDate.add(1, "day")
    } else {
      return unavailabilityRawEndDate
    }
  }
}

export const getDateRange = function (firstDate: string, lastDate: string): Array<string> {
  if (moment(firstDate, "YYYY-MM-DD").isSame(moment(lastDate, "YYYY-MM-DD"), "day")) {
    return [lastDate]
  }
  let date = firstDate
  const dates = [date]
  do {
    date = moment(date).add(1, "day")
    dates.push(date.format("YYYY-MM-DD"))
  } while (moment(date).isBefore(lastDate))
  return dates
}

export const getPeriodForVisibleRoster = function (
  payFrequency: ?string,
  payPeriodStart: moment,
  payPeriodFinish: moment,
  visibleStart: moment,
  visibleFinish: moment
): [moment, moment] {
  if (
    !payFrequency ||
    (payPeriodStart.isSameOrBefore(visibleStart) && payPeriodFinish.isSameOrAfter(visibleStart)) ||
    (payPeriodStart.isSameOrAfter(visibleStart) && payPeriodFinish.isSameOrBefore(visibleFinish))
  ) {
    return [payPeriodStart, payPeriodFinish]
  }

  const direction = payPeriodFinish.isAfter(visibleFinish) ? -1 : 1

  let newPayPeriodStart = payPeriodStart.clone()
  let newPayPeriodFinish = payPeriodFinish.clone()
  switch (payFrequency) {
    case "fortnightly":
      do {
        newPayPeriodStart.add(2 * direction, "weeks")
        newPayPeriodFinish.add(2 * direction, "weeks")
      } while (newPayPeriodStart.isAfter(visibleStart) || newPayPeriodFinish.isBefore(visibleStart))
      break
    case "semi_monthly":
      const start = payPeriodStart.date()
      const finish = payPeriodFinish.date()
      const startDay = start < finish ? start : finish
      const finishDay = start < finish ? finish : start

      const firstStartDateInMonth = newPayPeriodStart
        .month(visibleStart.month())
        .year(visibleStart.year())
        .date(startDay)
      newPayPeriodFinish.month(visibleStart.month()).year(visibleStart.year()).date(finishDay)
      if (direction === 1 && newPayPeriodFinish.isBefore(visibleStart)) {
        newPayPeriodStart = newPayPeriodFinish.clone().add(1, "days")
        newPayPeriodFinish = firstStartDateInMonth.clone().add(1, "month").subtract(1, "days")
      } else if (direction === -1 && newPayPeriodStart.isAfter(visibleStart)) {
        newPayPeriodStart = newPayPeriodFinish.clone().subtract(1, "month").add(1, "days")
        newPayPeriodFinish = firstStartDateInMonth.clone().subtract(1, "days")
      }
      break
    case "monthly":
      newPayPeriodStart.month(visibleStart.month()).year(visibleStart.year())
      newPayPeriodFinish = newPayPeriodStart.clone().add(1, "month").subtract(1, "days")
      break
    default:
      do {
        newPayPeriodStart.add(direction, "weeks")
        newPayPeriodFinish.add(direction, "weeks")
      } while (newPayPeriodStart.isAfter(visibleStart) || newPayPeriodFinish.isBefore(visibleStart))
      break
  }

  return [newPayPeriodStart, newPayPeriodFinish]
}

export const periodsWithinVisibleRoster = function (
  payFrequency: ?string,
  payPeriodStart: moment,
  payPeriodFinish: moment,
  visibleFinish: moment
): Array<Array<moment>> {
  const periods = [[payPeriodStart, payPeriodFinish]]
  const periodStart = payPeriodStart.clone()
  const periodFinish = payPeriodFinish.clone()
  let weeks = 0
  switch (payFrequency) {
    case "fortnightly":
      weeks = 2
      while (periodFinish.clone().add(weeks, "weeks").isSameOrBefore(visibleFinish)) {
        periods.push([
          periodFinish
            .clone()
            .add(weeks - 2, "weeks")
            .add(1, "days"),
          periodFinish.clone().add(weeks, "weeks"),
        ])
        weeks += 2
      }
      break
    // monthly and semi-monthly periods do not need to be looped as they get outside the month range in one iteration
    case "semi_monthly":
      // moment mutates the original date when using .add()
      if (periodStart.add(1, "months").isSameOrBefore(visibleFinish)) {
        periods.push([periodFinish.clone().add(1, "days"), periodStart])
      }
      break
    case "monthly":
      if (periodFinish.add(1, "month").isSameOrBefore(visibleFinish)) {
        periods.push([periodFinish.clone().add(1, "days"), periodFinish])
      }
      break
    default:
      weeks = 1
      while (periodFinish.clone().add(weeks, "weeks").isSameOrBefore(visibleFinish)) {
        periods.push([
          periodFinish
            .clone()
            .add(weeks - 1, "weeks")
            .add(1, "days"),
          periodFinish.clone().add(weeks, "weeks"),
        ])
        weeks++
      }
      break
  }
  return periods
}

export const unavailabilityFinishesAtMidnight = function (date: moment): boolean {
  return date.hours() === 0 && date.minutes() === 0 && date.seconds() === 0
}

export const unavailabilitySpansOverNight = function (unavailability: UnavailabilityRubyType): boolean {
  return unavailability.end < unavailability.start && unavailability.end !== unavailability.start
}

export const evaluateRules = (
  previous: CustomValidationObject,
  current: CustomValidationObject
): CustomValidationObject => {
  if (
    previous == null ||
    previous.fields == null ||
    previous.fields.max_hours_in_week == null ||
    previous.id === current.id
  ) {
    return current
  }
  if (current.fields?.max_hours_in_week == null) {
    return previous
  }

  return previous.fields.max_hours_in_week < current.fields?.max_hours_in_week ? previous : current
}

export const userLeaveForDates = (
  leave: Array<LeaveRequestRubyType>,
  shifts: Array<ShiftType>,
  dates: Array<string>
): Array<LeaveRequestRubyType> => {
  if (!leave?.length) {
    return leave
  }
  return _.flatten(
    dates.map((date) =>
      // create a flat array of leave requests that are within the date range
      _.flatten(
        // map through all leave requests and filter out the ones that are outside the date range
        leave
          .filter((lr) => lr.start <= date && lr.finish >= date)
          .map((lr) => {
            let summariesOnDate
            // This path will be taken if it's old leave with daily breakdown from the database column
            if (lr.daily_breakdown != null && lr.daily_breakdown.length > 0) {
              summariesOnDate = lr.daily_breakdown
                .filter((shift_summary: ShiftSummary) => shift_summary.date === date)
                .map((shift_summary: ShiftSummary) => ({
                  ...lr,
                  hours: shift_summary.hours,
                }))
            } else {
              // This path will be taken if it's new leave

              summariesOnDate = shifts
                .filter((s) => s.date === date && s.leave_request_id === lr.id)
                .map((s) => ({
                  ...lr,
                  hours: s.shift_length,
                }))
            }
            if (summariesOnDate.length === 0) {
              return lr
            }
            return summariesOnDate
          })
      )
    )
  )
}
