// @flow

import _ from "lodash"
import * as HelperFunc from "rosters/overview/helpers/functions"
import type { ShiftType } from "./types"

export const by15Minutes = (
  shifts: Array<ShiftType>,
  ref_date: string,
  hours: number = 48
): { [min: string]: number } => {
  const ref_date_unix = Math.floor(HelperFunc.dateTimeToMin(ref_date + " 00:00:00"))
  const time_idx: Array<number> = _.range(0, hours * 60, 15)
  const simple_shift_ds: Array<SimpleShiftType> = shifts
    .filter((s) => s.start && s.end)
    .map((s) => transformToSimpleDS(s, ref_date_unix))
  const obj: { [min: string]: number } = {}
  time_idx.forEach((min) => {
    obj[String(min)] = simple_shift_ds.filter((s) => duringTime(s, min)).length
  })
  return obj
}

export const duringTime = (shift: SimpleShiftType, min: number): boolean =>
  shift.start <= min && shift.finish > min && _.every(shift.breaks.map((b) => !(b.start <= min && b.finish > min)))

export type SimpleShiftType = {|
  breaks: Array<{ finish: number, start: number }>,
  finish: number,
  id: number,
  start: number,
|}

export const transformToSimpleDS = (shift: ShiftType, ref_time_to_subtract: number): SimpleShiftType => {
  const start_date = (shift.start || "").split(" ")[0]
  return {
    id: shift.id,
    start: Math.floor(HelperFunc.dateTimeToMin(shift.start || "") - ref_time_to_subtract) || 0,
    finish: Math.floor(HelperFunc.dateTimeToMin(shift.end || "")) - ref_time_to_subtract || 0,
    breaks: (shift.break_start && shift.break_end ? shift.break_start + "-" + shift.break_end : "")
      .split(",")
      .filter((b) => !!b)
      .map((b) => {
        const b_parts = b.split("-")
        const b_obj = {
          start: Math.floor(HelperFunc.dateTimeToMin(start_date + " " + b_parts[0])) - ref_time_to_subtract,
          finish: Math.floor(HelperFunc.dateTimeToMin(start_date + " " + b_parts[1])) - ref_time_to_subtract,
        }
        if (b_obj.finish < b_obj.start) {
          b_obj.finish = b_obj.finish + 24 * 60 * 60
        }
        return b_obj
      }),
  }
}
