// @flow

import moment from "moment"
import { DEFAULT_STATE as MODEL_DEFAULT_STATE } from "rosters/overview/models"
import * as C from "rosters/WebpackRosters/consts"
import * as Types from "../types"

export const DEFAULT_SCHEDULE_BREAK: Types.ScheduleBreakType = {
  id: null,
  finish: "",
  length: 0,
  paid: false,
  paid_meal_break: false,
  start: "",
  automatic_break: false,
}

export const DEFAULT_SCHEDULE: Types.ScheduleType = {
  automatic_break_length: null,
  breaks: "",
  created_at: "",
  creation_method: null,
  updated_at: null,
  daily_schedule_id: -1,
  date: "",
  department_id: -1,
  finish: null,
  id: -1,
  last_costed_at: "",
  last_published_at: null,
  record_id: -1,
  schedule_breaks: [],
  shift_detail_id: null,
  sort_order: 0,
  start: null,
  user_id: -1,
  platform_record_id: -1,
}

export const DEFAULT_USER_DAILY_SCHEDULE_JOIN: Types.UserDailyScheduleJoinType = {
  daily_schedule_id: -1,
  date: "",
  id: -1,
  last_published_at: null,
  user_id: -1,
}

export const DEFAULT_PUBLISHED_SCHEDULE: Types.PublishedScheduleType = {
  automatic_break_length: null,
  breaks: "",
  created_at: "",
  updated_at: null,
  daily_schedule_id: -1,
  date: "",
  department_id: -1,
  finish: null,
  id: -1,
  schedule_id: -1,
  last_costed_at: "",
  last_published_at: null,
  shift_detail_id: null,
  published_schedule_breaks: [],
  sort_order: 0,
  start: null,
  user_id: -1,
  platform_record_id: -1,
}

export const CHART_COLORS = {
  timesheet_color: "rgba(234, 14, 98, 0.8)",
  rostered_color: "rgba(0, 51, 163, 0.8)",
  actual_color: "rgba(234, 14, 14, 0.5)",
  predicted_color: "rgba(54, 183, 244, 0.4)",
  actual_tools: "rgba(234, 14, 14, 0.6)",
  predicted_tools: "rgba(54, 183, 244, 0.6)",
}
export const CHART_KEYS = {
  total_rostered_staff: "total_rostered_staff",
  total_recommended_staff: "total_recommended_staff",
  total_timesheet_staff: "total_timesheet_staff",
  total_ideal_staff: "total_ideal_staff",
}
export const DEFAULT_TEMPLATE: Types.TemplateRubyType = {
  id: -1,
  length: 7,
  name: "",
  start_date: "",
  location_ids: [],
  template_type: "RosterTemplate",
}

export const DEFAULT_ROSTER_TEMPLATE_USER_JOIN: Types.TemplateUserJoinRubyType = {
  department_id: -1,
  finish: "",
  id: -1,
  note: "",
  offset: 0,
  roster_template_id: -1,
  shift_detail_id: -1,
  start: "",
  user_id: -1,
  employment_contract_id: -1,
}

export const DEFAULT_SIMPLE_SCHEDULE: Types.SimpleScheduleType = {
  id: -1,
  start: 0,
  finish: 0,
  schedule_breaks: [],
}

export const DEFAULT_STATE = MODEL_DEFAULT_STATE

export const DEFAULT_REQUEST = { promise: (Promise.resolve(): Promise<void>), type: "default" }

export const DEFAULT_COGNITIVE_DEMAND_CONFIG: Types.CognitiveDemandConfigType = {
  daily_schedule_id: -1,
  growth_percentage: 1.0,
  growth_percentages_by_ds_id: {},
  id: -1,
  location_id: -1,
  multiple_dates: [],
  uses_fine_tune: false,
  available_dates: [],
}

export const DEFAULT_COGNITIVE_DEMAND_CONFIG_MOMENTIZED: Types.CognitiveDemandConfigMomentizedType = {
  daily_schedule_id: -1,
  growth_percentage: 1.0,
  growth_percentages_by_ds_id: {},
  id: -1,
  location_id: -1,
  multiple_dates: [],
  available_dates: [],
  uses_fine_tune: false,
}

export const DEFAULT_COGNITIVE_CREATOR_CONFIGURATION: Types.CognitiveCreatorConfigurationType = {
  id: -1,
  department_id: -1,
  minimum_staff: 0,
  max_concurrent_start: 0,
  min_length: null,
  max_length: null,
  time_to_open: 0.0,
  time_to_close: 0.0,
  alias_department_ids: [],
  undercoverage_penalty_ratio: 10.0,
  created_at: "",
  updated_at: "",
  maximum_staff: null,
  round_down_head_count: false,
  consolidate_shift_count: false,
}

export const DEFAULT_SPAN_OF_BUSINESS_HOURS: Types.SpanOfHoursType = {
  start: 6 * 60,
  finish: 30 * 60,
}

export const DEFAULT_RDO: Types.RDORubyType = {
  date: "2000-01-01",
  department_id: null,
  user_id: -1,
  daily_schedule_id: -1,
  id: -1,
  start: "2000-01-01 00:00:00",
  finish: "2000-01-02 00:00:00",
  shift_id: null,
  type: "RosteredDayOff",
}

export const DEFAULT_SHIFT_DETAIL: Types.ShiftDetailRubyType = {
  id: -1,
  name: "+",
  department_id: -1,
}

export const DEFAULT_LEAVE: Types.LeaveRequestRubyType = {
  all_day: false,
  approved_at: null,
  approved_by: null,
  created_at: "2000-01-01T00:00:00",
  daily_breakdown: [],
  finish: "2000-01-01",
  finish_time: null,
  hours: 0,
  id: -1,
  leave_type: "",
  reason: "",
  rejected_at: null,
  rejected_by: null,
  start: "2000-01-01",
  start_time: null,
  status: "approved",
  updated_at: "",
  user_id: -1,
}

export const LSKEYS = {
  projections_table_interval: "day_view.projections_table_interval",
  projections_order: "day_view.projections.order",
  custom_user_sort: "rosters_overview.view_options.custom_sort.user",
  visible_start_time: "rosters_overview.settings.start_half_hour",
  visible_finish_time: "rosters_overview.settings.finish_half_hour",
  expanded_groups: "rosters_overview.view_options.expanded_groups",
  show_teams_on_small_cards: "rosters_overview.view_options.show_teams_on_small_cards",
  show_staff_ratings: "rosters_overview.view_options.show_staff_ratings",
  hide_people_with_no_shifts: "rosters_overview.view_options.hide_people_with_no_shifts",
  user_key_stat: "rosters_overview.view_options.key_stat",
  day_key_stat: "rosters_overview.view_options.day_key_stat",
  day_key_stat_two: "rosters_overview.view_options.day_key_stat_two",
  day_key_stat_three: "rosters_overview.view_options.day_key_stat_three",
  last_used_view: "rosters_overview.last_used_view",
  roster_view: "rosters_overview.view_options.roster_view",
  day_view_roster_view: "rosters_overview.view_options.day_view_roster_view",
  group: "rosters_overview.view_options.group",
  collapse_multiple_cards: "rosters_overview.view_options.collapse_multiple_cards",
  active_reporting_view: "rosters_overview.view_options.active_reporting_view.",
  day_view_expanded_dep: "rosters_overview.day_view.expanded_dep.",
  key_stat_1: "rosters_overview.view_options.key_stats.stat_1",
  key_stat_2: "rosters_overview.view_options.key_stats.stat_2",
  key_stat_3: "rosters_overview.view_options.key_stats.stat_3",
  day_view_projections_table_dsj_order: "day_view.projections.order",
  sort_staff: "rosters_overview.view_options.sort_staff",
  day_view_graph_mode: "day_view.active_tab",
  printing_options: {
    // keys here must be named identically to keys in the state
    day_by_day: "printing_options.day_by_day",
    simulate_portrait_a4: "printing_options.simulate_portrait_a4",
    simulate_landscape_a4: "printing_options.simulate_landscape_a4",
    show_reasons: "printing_options.show_reasons",
    show_time_off: "printing_options.show_time_off",
    show_time_off_day_view: "printing_options.show_time_off_day_view",
    show_recommended_distributions: "printing_options.show_recommended_distributions",
  },
  prev_schedule_sort: "rosters_overview.view_options.prev_schedule_sort",
  schedule_sort_lock: "rosters_overview.view_options.schedule_sort_lock",
}

export const ParamKeys = {
  START_DATE_KEY: "start_date",
  FINISH_DATE_KEY: "finish_date",
  LOCATIONS_KEY: "locations",
  TEAMS_KEY: "teams",
}

export const ELEMENT_IDS = { content_container: "FixedSizeRosterContainer" }

export const DEFAULT_UNAVAILABILITY: Types.UnavailabilityRubyType = {
  all_day: false,
  class: "",
  end: "",
  start: "",
  title: "",
  user_id: -1,
  user_name: "",
}

export const DEFAULT_LOCATION: Types.LocationRubyType = {
  id: -1,
  name: "",
  short_name: "",
}
export const DEFAULT_TEAM_COLOR = "#CAD2DE"

export const DEFAULT_TEAM: Types.TeamRubyType = {
  colour: DEFAULT_TEAM_COLOR,
  department_group_id: null,
  sort_order: null,
  id: -1,
  location_id: -1,
  name: "No team assigned",
  short_name: "+",
  department_group_name: null,
}

export const DEFAULT_TEAM_GROUP: Types.TeamGroupType = {
  id: -1,
  default_team_id: -1,
  name: "No Team Group",
  location_id: DEFAULT_LOCATION.id,
  colour: DEFAULT_TEAM_COLOR,
  sort_order: null,
}

export const DEFAULT_POSITION_GROUP: Types.PositionGroupType = {
  id: -1,
  name: "No Position",
}

export const DEFAULT_POSITION: Types.PositionType = {
  id: -1,
  name: "No Position",
  employment_condition_set_template_id: -1,
  archived: false,
  comparison_condition_set_template_id: undefined,
  document_group_ids: [],
  managed_document_ids: [],
  position_group_id: -1,
  onboarding_form_ids: [],
  contract_template_id_or_fixtures: [],
  qualification_ids: [],
  department_ids: [],
}

export const DEFAULT_GROUP: Types.GroupInfoType = {
  color: "",
  id: "-1",
  name: "No Group",
  sort_order: null,
}

export const DEFAULT_USER: Types.UserType = {
  cached_url: null,
  contactable: false,
  date_of_birth: null,
  email_present: false,
  phone_present: false,
  uses_mobile_app: false,
  employment_end_date: null,
  employment_start_date: null,
  id: -1,
  name: "Vacant",
  preferred_hours: null,
  ratings: {},
  report_department_id: -1,
  sort_order: Number.MAX_SAFE_INTEGER,
  applies_to_ppt_compliance: false,
  blocked_if_missing_rhw: false,
  hourly_rate: 0,
  has_fixed_cost_salary_allocation: false,
  fixed_cost_daily_salary: 500,
  salary: 0,
  is_active: true,
  pay_frequency: "weekly",
  pay_period_finish: "2000-01-07",
  pay_period_start: "2000-01-01",
  protected_rate: false,
  award_tags: [],
  updated_at: "2000-01-01 00:00:00",
  user_has_no_roles: false,
  temporary_employee: false,
  temporary_employee_type: null,
  onboarding_status: "not_applicable",
}

export const DEFAULT_ONCOST_CONFIGURATION: Types.OncostConfigurationType = {
  config_name: "",
  config_value: 0,
  employee_tags: [],
  location_tags: [],
  only_applies_to_ordinary_hours: false,
  pension_fund: false,
}

export const DEFAULT_CUSTOM_EVENT: Types.CustomEventRubyType = {
  blackout_leave: false,
  blackout_unavailability: false,
  can_edit: true,
  discourage_time_off: false,
  discourage_unavailability: false,
  finish: "2000-01-01",
  id: -1,
  locations: [],
  departments: [],
  max_request_count: 0,
  max_unavailability_count: 0,
  name: "",
  selected_leave_types: [],
  start: "2000-01-01",
}

export const DEFAULT_BUSINESS_HOURS: Types.BusinessHoursType = {
  finish: 22 * 60,
  id: -1,
  location_id: -1,
  start: 4 * 60,
  weekday: 0,
}

export const DEFAULT_SHIFT_SLOT: Types.ShiftSlotType = {
  ending_after: null,
  ending_before: null,
  id: -1,
  name: "",
  sort_order: 1,
  starting_after: null,
  starting_before: null,
  colour: null,
}

const DEFAULT_DEFAULT_VALIDATION_OBJECT: Types.DefaultValidationObject = {
  id: 1,
  enabled: true,
  block_roster_publishing: true,
}

const DEFAULT_DEFAULT_VALIDATION_SETTINGS: Types.DefaultValidationSettings = {
  leave_clash: DEFAULT_DEFAULT_VALIDATION_OBJECT,
}

export const DEFAULT_DEFAULT_VALIDATION_FIELD_SETTINGS: Types.DefaultValidationFieldSettings = {
  earliest_start: 4 * 60, // 4am
  latest_finish: 23 * 60, // 11pm
  max_hours_in_week: 40,
  max_hours_worked_including_breaks: 12,
  max_hours_in_day: null,
  max_length: "11.5",
  max_shifts_in_day: 2,
  max_shifts_in_week: 6,
  min_gap: "3",
  min_length: "3",
  block_roster_publishing: false,
}

export const DEFAULT_CUSTOM_VALIDATION_OBJECT: Types.CustomValidationObject = {
  id: 1,
  enabled: true,
  block_roster_publishing: false,
  date_filters: { date_ranges: {}, selected_days: [] },
  filters: { location_ids: [] },
  fields: {
    max_hours_in_week: null,
    earliest_start: null,
    latest_finish: null,
    max_hours_worked_including_breaks: null,
    max_hours_in_day: null,
    max_length: null,
    max_shifts_in_day: null,
    max_shifts_in_week: null,
    min_gap: null,
    min_length: null,
  },
  name: null,
  overtime_conditions: [],
  validate_ot_shifts: false,
  validation_type: "custom",
}

const DEFAULT_CUSTOM_VALIDATION_SETTINGS: Types.CustomValidationSettings = {
  "1": DEFAULT_CUSTOM_VALIDATION_OBJECT,
}

// used in mocked data and test files
export const DEFAULT_TRANSFORMED_PROPS: Types.TransformedRubyStateType = {
  archived_teams: [],
  labour_budgets: [],
  sales_budgets: [],
  metric_budgets: [],
  new_labour_budgets_enabled: false,
  budget_config: {
    budget_employee_metric_type: "",
    budget_metric_metric_type: "",
    enforced_visible_roster_metrics: [],
    organisation_id: -1,
  },
  enable_automatic_breaks: false,
  rule_sets: [],
  data_stream_joins: [],
  head_count_maps: [],
  cognitive_creator_configurations: [],
  oncost_configurations: [],
  config: {
    can_create_paid_meal_breaks: false,
    current_user_id: -1,
    shift_claiming_enabled: false,
    shift_swapping_enabled: false,
    platform_model: DEFAULT_STATE.config.platform_model,
    enable_full_location_rosters: false,
    enable_shift_acceptance: false,
    enable_secondary_departments: false,
    enable_staff_type_filter: false,
    sms_enabled: false,
    show_weekends: true,
    employee_custom_validations_map: {},
    labour_budget_enabled: false,
    deps_users_can_work_in: [],
    pay_period_length: 14,
    roster_week_start_day: 1,
    enable_roster_validation: true,
    enable_task_based_rostering: true,
    default_validation_settings: DEFAULT_DEFAULT_VALIDATION_SETTINGS,
    default_validation_field_settings: DEFAULT_DEFAULT_VALIDATION_FIELD_SETTINGS,
    custom_validation_settings: DEFAULT_CUSTOM_VALIDATION_SETTINGS,
    recent_pay_period_start: moment("2019-01-01", C.DATE_FMT),
    using_ppt_acceptance: false,
    business_hours: [],
    managed_team_ids: [],
    visible_user_ids: [],
    publishable_team_ids: [],
    shift_slots: [],
    permissions: {
      can_create_leave: true,
      can_approve_leave: true,
      can_approve_own_leave: true,
      can_update_leave: true,
      can_create_timesheets: true,
      can_update_shift_proposal: true,
      can_publish_rosters: true,
      can_create_schedule: true,
      can_update_schedule: true,
      can_lock_rosters: true,
      can_import_ratios: true,
      can_update_open_hour_periods: true,
      can_view_projections_table_configuration: true,
      can_update_projections_table_configuration: true,
      roster_templates: {
        create: true,
        delete: true,
        read: true,
        update: true,
      },
    },
    organisation: DEFAULT_STATE.config.organisation,
  },
  data_streams: [],
  locations: [],
  departments: [],
  roster_pattern_rdos: [],
  shift_details: [],
  teams: [],
  team_groups: [],
  roster_pattern_schedules: [],
  cross_department_proficiencys: [],
  roster_pattern_user_joins: [],
  roster_patterns: {},
  users: [],
  user_qualifications: [],
  qualifications: [],
  department_qualifications: [],
  training_logs: [],
  templates: [],
  stat_types: [],
  template_user_joins: [],
  position_groups: [],
  positions: [],
  employment_condition_sets: [],
}

export const DEFAULT_COMMENT: Types.CommentRubyType = {
  can_edit: true,
  finish: "2020-01-01",
  id: -1,
  locations: [],
  comment: "",
  created_at: "2020-01-01 00:00:00",
  show_staff: false,
  start: "2020-01-01",
  user_id: null,
  user_name: null,
}

export const DEFAULT_AWARD: Types.AwardType = {
  active_rules: [],
  color: "",
  has_min_length: false,
  id: -1,
  is_leave: false,
  is_ord_hours: true,
  multiplier: 1.0,
  name: "",
  rates: {
    hourly_rate: null,
    multiplier: 1.0,
    ordinary_hours: null,
  },
}

// Used for tracking how schedules are created
export const CREATION_METHOD = {
  auto_scheduled: "auto_scheduled",
  manual: "manual",
  copy: "copy",
  schedule_pattern: "schedule_pattern",
  regular_hours: "regular_hours",
  template: "template",
}

// Elando creates a datastream when enabled on the org. By default with how datastreams are configured, this can create confusing/redundant info in the UI.
// This stat type is used to filter/differentiate the redudant UI created by Elando.
export const ELANDO_STAT_TYPE: string = "elando"
export const BUDGET_STAT_TYPE: string = "labour budget"
export const ELANDO_STAT_TYPE_CAPITALIZED: string = "Elando"

export const STATIC_STAT_TYPE: string = "static"

export const AVERAGE_OF_DATES: string = "average_of_dates"

export const LIMBER: string = "limber"

export const EARNINGS_THRESHOLDS_FOR_PENSION_ONCOSTS_UK = [
  { start_date: "2015-04-01", finish_date: "2016-03-31", threshold: 676, threshold_for_juniors: 3532 },
  { start_date: "2016-04-01", finish_date: "2017-03-31", threshold: 676, threshold_for_juniors: 3583 },
  { start_date: "2017-04-01", finish_date: "2018-03-31", threshold: 680, threshold_for_juniors: 3750 },
  { start_date: "2018-04-01", finish_date: "2019-03-31", threshold: 702, threshold_for_juniors: 3863 },
  { start_date: "2019-04-01", finish_date: "2020-03-31", threshold: 702, threshold_for_juniors: 3863 },
  { start_date: "2020-04-01", finish_date: "2021-03-31", threshold: 719, threshold_for_juniors: 4167 },
  { start_date: "2021-04-01", finish_date: "2022-03-31", threshold: 792, threshold_for_juniors: 4167 },
  { start_date: "2022-04-01", finish_date: "2023-03-31", threshold: 797, threshold_for_juniors: 4189 },
  { start_date: "2023-04-01", finish_date: "2024-03-31", threshold: 1048, threshold_for_juniors: 4189 },
  { start_date: "2024-04-01", finish_date: "2025-03-31", threshold: 1048, threshold_for_juniors: 4189 },
]
