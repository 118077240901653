/* eslint flowtype/require-valid-file-annotation: off */ /* TODO: flow type this file, remove this lint disable, get a maxibon */

import * as React from "react"
import PropTypes from "prop-types"
import { t } from "helpers/i18n"
import * as NumberHelpers from "helpers/number_helpers"

import * as Shift from "timesheets/models/shift"
import * as Timesheet from "timesheets/models/timesheet"

import { AutoHoverBubble } from "components/Bubble"
import HoverIcon from "components/HoverIcon"

import Button from "components/Button"
import styles from "./styles.module.scss"

export default class Allowances extends React.PureComponent {
  constructor(props) {
    super(props)
    this.handleOpenAllowancesModal = this.handleOpenAllowancesModal.bind(this)
  }

  handleOpenAllowancesModal() {
    if (!this.isDisabled()) {
      this.props.actions.appOpenAllowanceModal(this.props.shift.get("id"))
    }
  }

  isDisabled() {
    return !this.props.shift.getIn(["permitted_actions", "configure"]) || Shift.hasMockedId(this.props.shift.get("id"))
  }

  renderOpenButton() {
    return (
      <Button
        aria-hidden
        iconLeft={Timesheet.isExported(this.props.timesheet) ? "visibility" : "add"}
        label={
          Timesheet.isExported(this.props.timesheet)
            ? t("js.timesheets.shift_card.allowances.view_button")
            : t("js.timesheets.shift_card.allowances.add_button")
        }
        onClick={this.handleOpenAllowancesModal}
        size="xs"
        type="ghost"
      />
    )
  }

  allowanceList() {
    return this.props.shift
      .get("shift_allowances")
      .filter((allowance) => allowance.get("value") !== 0)
      .toArray()
  }

  hasApplyingAllowances() {
    return Shift.hasAllowances(this.props.shift) && this.allowanceList().length > 0
  }

  renderAllowances() {
    return this.allowanceList().map((allowance) => {
      const name = allowance.get("name")
      const value = allowance.get("value")
      const payRateBased = allowance.get("pay_rate_based_period_of_time")
      const formattedUnits = NumberHelpers.toFormattedNumber(value, { decimals: 2 })

      const formattedValue = payRateBased
        ? `${formattedUnits} (${NumberHelpers.toFormattedNumber(Math.floor(value))}${t(
            "js.timesheets.shift_card.allowances.h"
          )} ${NumberHelpers.toFormattedNumber(Math.floor((value % 1) * 60))}${t(
            "js.timesheets.shift_card.allowances.m"
          )})`
        : formattedUnits

      return (
        <span className={styles.allowanceDescription} key={name}>
          {name} ({formattedValue})
        </span>
      )
    })
  }

  renderEmptyState() {
    return (
      <span className={styles.emptyState} key="-1">
        {t("js.timesheets.shift_card.allowances.no_allowances")}
      </span>
    )
  }

  renderNoTimesWarning() {
    return (
      <AutoHoverBubble offset={0.25} position="bottom">
        {t("js.timesheets.shift_card.allowances.add_times_first")}
      </AutoHoverBubble>
    )
  }

  renderAveragingTip() {
    return (
      <div>
        <div>{t("js.timesheets.shift_card.allowances.requires_averaging")}</div>
      </div>
    )
  }

  render() {
    return (
      <div className={styles.Allowances}>
        <div className={styles.content}>
          <span className={styles.title}>{t("js.timesheets.shift_card.allowances.allowances_span")}</span>
          <div className={styles.shiftAllowances}>
            {this.hasApplyingAllowances() ? this.renderAllowances() : this.renderEmptyState()}
            {Shift.hasMockedId(this.props.shift.get("id")) ? this.renderNoTimesWarning() : null}
          </div>
          {this.isDisabled() ? null : (
            <div className="ml1" data-testid="open-wrapper">
              {this.renderOpenButton()}
            </div>
          )}
          {this.props.requiresAveraging && (
            <div className={styles.leaveAveragingTooltip}>
              <HoverIcon tip={this.renderAveragingTip()} type="warning" width="large" />
            </div>
          )}
        </div>
      </div>
    )
  }
}

Allowances.propTypes = {
  actions: PropTypes.object.isRequired,
  requiresAveraging: PropTypes.bool,
  shift: PropTypes.object.isRequired,
  timesheet: PropTypes.object.isRequired,
}

Allowances.defaultProps = {
  requiresAveraging: false,
}
