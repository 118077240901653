// @flow

import Request from "helpers/request"
import * as Routes from "helpers/routes"
import { TEST_MODE } from "helpers/testMode"
import type { AICResponseType } from "../types"
import * as Mocks from "./mocks/costs"

export const getScheduleAICS: (
  schedule_ids: Array<number>,
  time_not_worked_schedule_ids: Array<number>,
  template_id: ?number
) => Promise<AICResponseType> = TEST_MODE
  ? Mocks.getScheduleAICS
  : (
      schedule_ids: Array<number>,
      time_not_worked_schedule_ids: Array<number>,
      template_id: ?number
    ): Promise<AICResponseType> => {
      const request_promise: Promise<AICResponseType> = Request.post("/rosters_overview/schedule_costs", {
        schedule_ids: schedule_ids,
        time_not_worked_schedule_ids: time_not_worked_schedule_ids,
        template_id: template_id,
      })
        .then((request: { data: AICResponseType }) => request.data)
        .catch((error) => ({ aics: [], schedules_still_in_queue: [], time_not_worked_schedules_still_in_queue: [] })) // eslint-disable-line handle-callback-err
      return request_promise
    }

export const recacheRostersAndUsers: (user_ids: Array<number>, roster_ids: Array<number>) => Promise<mixed> = TEST_MODE
  ? Mocks.recacheRostersAndUsers
  : (user_ids: Array<number>, roster_ids: Array<number>): Promise<mixed> => {
      const request_promise: Promise<mixed> = Request.post(Routes.recache_rosters_and_users_path(), {
        user_ids,
        roster_ids,
      })
      return request_promise
    }

export default getScheduleAICS
