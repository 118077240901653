// @flow

import Request from "helpers/request"
import * as Routes from "helpers/routes"
import { TEST_MODE } from "helpers/testMode"
import * as HelperFunc from "../helpers/functions"
import type {
  ScheduleType,
  ScheduleRubyType,
  LeaveRequestRubyType,
  ScheduleEditableParamsType,
  ScheduleRequestResponseType,
  ScheduleBulkCreateResponseType,
  ScheduleBulkRequestResponseType,
  ScheduleReloadResponseType,
  ScheduleBulkCreateOptionsType,
  RDORubyType,
} from "../types"
import * as Mocks from "./mocks/schedule"

export const editRequest: (
  previous_schedule_state: ScheduleType,
  template_id?: ?number,
  new_changes: ScheduleEditableParamsType
) => Promise<ScheduleRequestResponseType> = TEST_MODE
  ? Mocks.editRequest
  : (
      previous_schedule_state: ScheduleType,
      template_id: ?number = null,
      new_changes: ScheduleEditableParamsType
    ): Promise<ScheduleRequestResponseType> => {
      const transformed_changes = HelperFunc.transformEditableParamsToRubyType(new_changes)
      const request_promise: Promise<ScheduleRequestResponseType> = Request.post(
        "/rosters_overview/schedules/" + previous_schedule_state.id,
        { date: previous_schedule_state.date, template_id, ...transformed_changes }
      )
        .then((request: { data: ScheduleRubyType }) => {
          if (typeof request.data === "string") {
            // Api requests can sometimes 'succeed' by returning a redirect to the dashboard. This is actually a fail and should
            throw new Error("Schedule edit returned a redirect (302)")
          }
          return {
            sched: HelperFunc.transformSchedule(request.data),
            success: true,
            error_message: "",
          }
        })
        .catch((error) => ({
          sched: previous_schedule_state,
          success: false,
          error_message: String(error) || "",
        }))
      return request_promise
    }

export const createRequest: (
  new_schedule: ScheduleType,
  template_id?: ?number
) => Promise<ScheduleRequestResponseType> = TEST_MODE
  ? Mocks.createRequest
  : (new_schedule: ScheduleType, template_id: ?number = null): Promise<ScheduleRequestResponseType> => {
      const transformed_schedule = HelperFunc.transformScheduleToRubyType(new_schedule)
      const request_promise: Promise<ScheduleRequestResponseType> = Request.post("/rosters_overview/schedules", {
        date: new_schedule.date,
        template_id,
        ...transformed_schedule,
      })
        .then((request: { data: ScheduleRubyType }) => ({
          sched: HelperFunc.transformSchedule(request.data),
          success: true,
          error_message: "",
        }))
        .catch((error) => ({
          sched: new_schedule,
          success: false,
          error_message: String(error) || "",
          error: error,
        }))
      return request_promise
    }

export const deleteRequest: (
  schedule_to_delete: ScheduleType,
  template_id?: ?number
) => Promise<ScheduleRequestResponseType> = TEST_MODE
  ? Mocks.deleteRequest
  : (schedule_to_delete: ScheduleType, template_id: ?number = null): Promise<ScheduleRequestResponseType> => {
      const request_promise: Promise<ScheduleRequestResponseType> = Request.delete(
        "/rosters_overview/schedules/" + schedule_to_delete.id,
        { params: { date: schedule_to_delete.date, template_id } }
      )
        .then((request) => ({
          sched: schedule_to_delete,
          success: true,
          error_message: "",
        }))
        .catch((error) => ({
          sched: schedule_to_delete,
          success: false,
          error_message: String(error) || "",
        }))
      return request_promise
    }

export const bulkDeleteRequest: (
  schedules_to_delete: Array<ScheduleType>,
  template_id?: ?number
) => Promise<ScheduleBulkRequestResponseType> = TEST_MODE
  ? Mocks.bulkDeleteRequest
  : (
      schedules_to_delete: Array<ScheduleType>,
      template_id: ?number = null
    ): Promise<ScheduleBulkRequestResponseType> => {
      const request_promise: Promise<ScheduleBulkRequestResponseType> = Request.post(
        "/rosters_overview/bulk_schedules_destroy",
        { schedule_ids: schedules_to_delete.map((s) => s.id), template_id }
      )
        .then((request: { data: Array<number> }) => ({
          scheds: schedules_to_delete.filter((s) => request.data.includes(s.id)),
          success: true,
          error_message: "",
        }))
        .catch((error) => ({
          scheds: schedules_to_delete,
          success: false,
          error_message: String(error) || "",
        }))
      return request_promise
    }

export const bulkCreateRequest: (
  new_schedules: Array<ScheduleType>,
  transformed_rdos: Array<RDORubyType>,
  template_id?: ?number,
  options: ScheduleBulkCreateOptionsType
) => Promise<ScheduleBulkCreateResponseType> = TEST_MODE
  ? Mocks.bulkCreateRequest
  : (
      new_schedules: Array<ScheduleType>,
      transformed_rdos: Array<RDORubyType>,
      template_id: ?number = null,
      options: ScheduleBulkCreateOptionsType
    ): Promise<ScheduleBulkCreateResponseType> => {
      const transformed_schedules = new_schedules.map((s) => HelperFunc.transformScheduleToRubyType(s))
      const request_promise: Promise<ScheduleBulkCreateResponseType> = Request.post(
        Routes.rosters_overview_bulk_schedules_path(),
        { schedules: transformed_schedules, rdos: transformed_rdos, template_id, ...options }
      )
        .then(
          (request: {
            data: {
              leave_requests: Array<LeaveRequestRubyType>,
              rdos: Array<RDORubyType>,
              schedules: Array<ScheduleRubyType>,
            },
          }) => ({
            scheds: request.data.schedules.map((s) => HelperFunc.transformSchedule(s)),
            // Might create leave requests for automatic public holidays
            leave_requests: request.data.leave_requests.map((lr) => HelperFunc.transformLeave(lr)),
            rdos: request.data.rdos,
            success: true,
            error_message: "",
          })
        )
        .catch((error) => ({
          scheds: new_schedules,
          leave_requests: [],
          rdos: [],
          success: false,
          error_message: String(error) || "",
        }))
      return request_promise
    }

export const bulkSortOrder: (new_schedules: Array<{ id: number, sort_order: ?number, ... }>) => Promise<mixed> =
  TEST_MODE
    ? Mocks.bulkSortOrder
    : (new_schedules: Array<{ id: number, sort_order: ?number }>): Promise<mixed> =>
        Request.post(Routes.rosters_overview_schedules_bulk_sort_order_path(), { schedules: new_schedules })

export const reloadSchedules = (daily_schedule_id: number, user_id: number): Promise<ScheduleReloadResponseType> => {
  const req_promise: Promise<ScheduleReloadResponseType> = Request.get(Routes.reload_schedules_for_user_path(), {
    params: { user_id: user_id, daily_schedule_id: daily_schedule_id },
  })
    .then((request: { data: Array<ScheduleRubyType> }) => ({
      error_message: "",
      schedules: request.data.map(HelperFunc.transformSchedule),
    }))
    .catch((error) => ({
      error_message: String(error) || "",
      schedules: [],
    }))
  return req_promise
}
